<template>
  <v-col md="4" offset-md="4" sm="12">
    <v-autocomplete
      rounded
      append-icon=""
      solo
      :items="memberSearchItems"
      label="Member Search"
      hide-no-data
      item-text="name"
      item-value="id"
      :menu-props="{ closeOnClick: true }"
    >
      <template v-slot:item="memberSearchItems">
        <v-list-item
          :href="memberSearchItems.item.link"
        >
          <v-icon :color="iconColor(memberSearchItems.item.type)">{{ entityIcon(memberSearchItems.item.type) }}</v-icon> &nbsp; <span>{{ memberSearchItems.item.name }}</span>
        </v-list-item>
      </template>
      <template slot="append">
        <v-progress-circular
          v-if="this.isLoading"
          color="#1E345D"
          indeterminate
        ></v-progress-circular>
      </template>
    </v-autocomplete>
  </v-col>
</template>

<script>
import dashboardService from '../services/dashboard.service';

export default {
  data () {
    return {
      memberSearchItems: [],
      isLoading: false,
      companyListHeaders: [{ text: 'Member', value: 'name', align: 'center' }],
      search: ''
    };
  },
  mounted () {
    this.getMembers();
  },
  methods: {
    getMembers () {
      this.isLoading = true;
      dashboardService.getCompanies().then((res) => {
        res.data.companies.forEach((item) => {
          this.memberSearchItems.push({
            name: item.name,
            id: item.companyId,
            type: item.entityType,
            link: '/Dashboard/' + this.entityTypeToChar(item.entityType) + '/' + item.companyId.toString() + '/' + item.name
          });
        });
        this.isLoading = false;
      });
    },
    iconColor (type) {
      switch (type) {
        case 0:
          return '#19365d';
        case 1:
          return '#468847';
        case 2:
          return '#ed8b00';
        case 3:
          return '#3a87ad';
        case 4:
          return '#999999';
      }
    },
    entityTypeToChar (type) {
      switch (type) {
        case 0:
          return 'c';
        case 1:
          return 'g';
        case 2:
          return 'd';
        case 3:
          return 'm';
        case 4:
          return 'f';
      }
    },
    entityIcon (type) {
      switch (type) {
        case 0:
          return 'mdi-alpha-c-box';
        case 1:
          return 'mdi-alpha-g-box';
        case 2:
          return 'mdi-alpha-d-box';
        case 3:
          return 'mdi-alpha-m-box';
        case 4:
          return 'mdi-alpha-f-box';
      }
    },
    goTo (route) {
      this.$router.push(`/${route}`);
    }
  }
};
</script>

<style scoped lang="scss">
.search-bar {
  border-left: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
  padding-left: 5px;
  padding-right: 5px;
  border-top: 1px solid #dddddd;
}
#company-list {
  td {
    padding-top: 10px;
    padding-bottom: 5px;
    border-bottom: 0px;
    border-left: 1px solid #dddddd;
    border-right: 0px;
  }
}
</style>
