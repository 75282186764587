import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import '@mdi/font/css/materialdesignicons.css';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi' // default - only for display purposes
  },
  theme: {
    themes: {
      light: {
        primary: '#0074B8',
        secondary: '#ffffff',
        accent: '#DB7100',
        accentWhite: '#ffffff',
        error: '#CE391F',
        htBlue: '#1E345D'
      }
    }
  }
});
