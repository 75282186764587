export const messageOptions = {
  error: {
    showMessage: true,
    timeout: -1,
    customClass: 'warning-color'
  },
  success: {
    showMessage: true,
    timeout: 3000
  },
  info: {
    showMessage: true,
    timeout: 1000
  },
  default: {
    showMessage: true,
    timeout: 3000
  }
};

export const messages = {
  distributorListError: 'Unable to retrieve markup data at this time. Please try again later',
  distributorMarkupAmountEmpty: 'Markup is a required field',
  distributorExceptionMarkupEmpty: 'Exception markup is a required field',
  distributorMarkupDeleteSuccess: 'Successfully deleted distributor markup',
  distributorExceptionEmpty: 'Must add contract number and markup amount',
  distributorExceptionNoChanges: 'No changes have been made',
  distributorExceptionInvalidContractNumber: 'Contract number cannot be empty',
  distributorExceptionContractExists: 'A Markup with this contract number already exists',
  distributorExceptionDuplicate: 'Must only be one exception per contract number',
  distributorExceptionDeleteError: 'Error deleting markup, please try again later',
  distributorExceptionSaveError: 'Error saving markup, please try again later',
  distributorExceptionSaveSuccess: 'Successfully saved exception',
  distributorExceptionDeleteSuccess: 'Successfully deleted exception',
  distributorMarkupSaveSuccess: 'Successfully saved new distributor markup',
  itemExportEmptyInputs: 'Please input information into at least one input field to conduct an export.',
  itemExportNoResults: 'Result is empty, cannot export results.',
  itemExportInProgress: 'Item Export is in progress.',
  itemExportComplete: 'Item Export has completed',
  serverErrorDefault: 'An unknown error has occurred please try again later',
  userSignOut: 'Sign Out Successful. See you soon!'
};
