import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import './assets/stylesheets/main.scss';
import '@/directives';
import { LoadApplicationInsights } from './dependencyAccess/appInsights';
import featureFlagsManager from './dependencyAccess/featureFlags';
import userManager from './plugins/userManager';
import HighChartsVue from 'highcharts-vue';
import Highcharts from 'highcharts';

LoadApplicationInsights();

Highcharts.setOptions({
  lang: {
    numericSymbols: ['K', 'M', 'B', 'T']
  }
});

Vue.use(userManager);
Vue.use(featureFlagsManager);
Vue.use(HighChartsVue, { Highcharts });

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app');
