<template>
  <article>
    <header id="page-header" class="pt-10 pb-1 mb-5">
      <p id="admin-header">{{this.hierarchyLevel}}</p>
      <div class="horizontal-divider"></div>
      <v-container>
    </v-container>
    </header>
    <section>
      <v-container>
        <v-row class="hierarchy-breadcrumbs">
          <v-col offset-md="1" class="justify-center">
              <v-card-text class="text-center">
              <v-breadcrumbs
                :items="breadCrumbItems"
                divider=">"
              ></v-breadcrumbs>
            </v-card-text>
          </v-col>
        </v-row>
        <v-row class="px-3 pb-3">
          <v-col md="1" xs="12">
          </v-col>
          <v-col cols="12" md="10">
            <v-card>
              <v-data-table
                height="650"
                id="member-settings-table"
                fixed-header
                dense
                :loading="childrenTableLoading"
                :footer-props="{
                  'items-per-page-options': [10, 25, 50, 100]
                }"
                :headers="headers"
                :items="children">
                <template v-slot:item.name="{ item }">
                  <v-list-item
                    v-if="item.entityType !== 'f'"
                    class="member-settings-item"
                    :href="'/Admin/' + item.entityType + '/' + item.entityId + '/' + formatLinkName(item.name) + link"
                  >
                    {{ item.name }} &nbsp;
                    <v-btn
                      icon
                      :disabled="changeCount > 0"
                      :href="'/Dashboard/' + item.entityType + '/' + item.entityId + '/' + formatLinkName(item.name)"
                    >
                      <v-icon color="#1e345d">mdi-open-in-new</v-icon>
                    </v-btn>
                  </v-list-item>
                  <v-list-item v-else
                  >
                    {{ item.name }} &nbsp;
                    <v-btn
                      :disabled="changeCount > 0"
                      icon
                      :href="'/Dashboard/' + item.entityType + '/' + item.entityId + '/' + item.name"
                    >
                      <v-icon color="#1e345d">mdi-open-in-new</v-icon>
                    </v-btn>
                  </v-list-item>
                </template>
                <template v-slot:top>
                  <v-container>
                    <v-row>
                      <v-col>
                        <v-text-field
                          v-model="nameSearch"
                          label="Search Members by Name"
                          class="mx-4"
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <v-text-field
                          v-model="entityIdSearch"
                          label="Search Members by ID"
                          class="mx-4"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </template>
                <template v-slot:item.isVisible="{ item }">
                  <div class="checkbox-container">
                    <v-checkbox
                      color="#DB7100"
                      hide-details
                      @change="item.hasChanged = !item.hasChanged"
                      @click="updateChangeCount(item.hasChanged)"
                      v-model="item.isVisible"
                    ></v-checkbox>
                  </div>
                </template>
                <template v-slot:item.retainedIDs="{ item }">
                  <v-row>
                    <v-col v-if="item.retainedIDs.length > 0">
                      <v-list-item
                        dense
                        v-for="(id, i) in item.retainedIDs"
                        :key="i"
                      >
                        {{ id }}
                      </v-list-item>
                    </v-col>
                    <v-col>
                      <RetainedGPOID v-if="item.retainedIDs.length > 0"
                        class="mt-1"
                        :name="item.name"
                        :company-id="item.companyId"
                        :gpoid="item.gpoid"
                        :member-retained-ids="item.retainedIDs"
                        :retained-ids="retainedIds"
                        :icon="'mdi-pencil'"
                        :admin-page-changes="changeCount"
                        @modal-refresh="getCompanyInfo"
                      />
                      <RetainedGPOID v-else
                        :name="item.name"
                        :company-id="item.companyId"
                        :gpoid="item.gpoid"
                        :member-retained-ids="item.retainedIDs"
                        :retained-ids="retainedIds"
                        :icon="'mdi-plus'"
                        :admin-page-changes="changeCount"
                        @modal-refresh="getCompanyInfo"
                      />
                    </v-col>
                  </v-row>
                </template>
              </v-data-table>
            </v-card>
            <br/>
            <v-row>
              <v-col cols="12" lg="3" md="3" xl="2">
                <v-btn
                  class="submit-btn"
                  :disabled="changeCount === 0"
                  @click="saveIsVisibleChanges()"
                >
                  Save Changes &nbsp;
                  <v-icon>mdi-content-save</v-icon>
                </v-btn> &nbsp;
                <span>
                  <v-progress-circular
                    v-if="this.changesLoading === true"
                    color="#DB7100"
                    indeterminate
                  />
                  <v-icon v-if="this.saveChangesSuccessful === true" color="green">mdi-check-bold</v-icon>
                  <span v-if="this.saveChangesError != ''">{{ this.saveChangesError }}</span>
                </span>
              </v-col>
              <v-col>
                <CancelChanges @cancel-changes="cancelIsVisibleChanges" :change-count="changeCount"/>
              </v-col>
              <v-spacer/>
              <v-col>
                <v-btn
                  class="submit-btn"
                  @click="exportVisible"
                  :disabled="childrenTableLoading"
                >
                  Export Visible Entities &nbsp; <v-icon>mdi-file-excel</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </article>
</template>

<script>
import adminService from '../services/admin.service';
import dashboardService from '../services/dashboard.service';
import RetainedGPOID from '../components/RetainedGPOID.vue';
import CancelChanges from '../components/CancelChanges.vue';
import { json2excel } from 'js2excel';

export default {
  components: { RetainedGPOID, CancelChanges },
  data () {
    return {
      children: [],
      link: '',
      breadCrumbItems: [],
      retainedIds: [],
      childrenTableLoading: true,
      changeCount: 0,
      changesLoading: false,
      saveChangesSuccessful: false,
      saveChangesError: '',
      nameSearch: '',
      entityIdSearch: '',
      gpoidSearch: '',
      retainedSearch: '',
      hierarchyLevel: '',
      headers: [
        { text: '', value: 'name', filter: this.nameFilter },
        { text: 'Is Visible', value: 'isVisible', align: 'center' },
        { text: 'Entity ID', value: 'entityId', filter: this.entityIdFilter },
        { text: 'Parent ID', value: 'parentId' }
      ]
    };
  },
  mounted () {
    this.getChildrenInfo();
  },
  methods: {
    getChildrenInfo () {
      this.childrenTableLoading = true;
      this.changeCount = 0;
      this.children = [];
      switch (this.$route.params.entityType) {
        case 'c':
          dashboardService.groups(this.$route.params.entityId).then((res) => {
            res.data.groups.forEach((item) => {
              this.children.push({
                name: item.name,
                entityId: item.groupId,
                parentId: item.companyId,
                parentName: this.$route.params.entityName,
                entityType: 'g',
                isVisible: item.isVisible,
                hasChanged: false
              });
            });
            this.hierarchyLevel = 'Groups';
            this.headers[0].text = 'Group Name';
            this.link = '/' + this.formatLinkName(this.$route.params.entityName) + '/' + this.$route.params.entityId;
            this.breadCrumbItems = [
              {
                text: 'Companies - ' + this.$route.params.entityName,
                disabled: false,
                href: '/Admin'
              },
              {
                text: 'Groups',
                disabled: false,
                href: this.$route.fullPath
              }
            ];
            this.childrenTableLoading = false;
          });
          break;
        case 'g':
          dashboardService.divisions(this.$route.params.entityId).then((res) => {
            res.data.divisions.forEach((item) => {
              this.children.push({
                name: item.name,
                entityId: item.divisionId,
                parentId: item.groupId,
                parentName: this.$route.params.entityName,
                entityType: 'd',
                isVisible: item.isVisible,
                hasChanged: false
              });
            });
            this.hierarchyLevel = 'Divisions';
            this.headers[0].text = 'Division Name';
            this.link = '/' + this.formatLinkName(this.$route.params.company) + '/' + this.$route.params.companyId + '/' + this.formatLinkName(this.$route.params.entityName) + '/' + this.$route.params.entityId;
            this.breadCrumbItems = [
              {
                text: 'Companies - ' + this.$route.params.company,
                disabled: false,
                href: '/Admin'
              },
              {
                text: 'Groups - ' + this.$route.params.entityName,
                disabled: false,
                href: '/Admin/c/' + this.$route.params.companyId + '/' + this.formatLinkName(this.$route.params.company)
              },
              {
                text: 'Divisions',
                disabled: false,
                href: this.$route.fullPath
              }
            ];
            this.childrenTableLoading = false;
          });
          break;
        case 'd':
          dashboardService.markets(this.$route.params.entityId).then((res) => {
            res.data.markets.forEach((item) => {
              this.children.push({
                name: item.name,
                entityId: item.marketId,
                parentId: item.divisionID,
                parentName: this.$route.params.entityName,
                entityType: 'm',
                isVisible: item.isVisible,
                hasChanged: false
              });
            });
            this.hierarchyLevel = 'Markets';
            this.headers[0].text = 'Market Name';
            this.link = '/' + this.formatLinkName(this.$route.params.company) + '/' + this.$route.params.companyId + '/' + this.formatLinkName(this.$route.params.group) + '/' + this.$route.params.groupId + '/' + this.formatLinkName(this.$route.params.entityName) + '/' + this.$route.params.entityId;
            this.breadCrumbItems = [
              {
                text: 'Companies - ' + this.$route.params.company,
                disabled: false,
                href: '/Admin'
              },
              {
                text: 'Groups - ' + this.$route.params.group,
                disabled: false,
                href: '/Admin/c/' + this.$route.params.companyId + '/' + this.formatLinkName(this.$route.params.company)
              },
              {
                text: 'Divisions - ' + this.$route.params.entityName,
                disabled: false,
                href: '/Admin/g/' + this.$route.params.groupId + '/' + this.formatLinkName(this.$route.params.group) + '/' + this.formatLinkName(this.$route.params.company) + '/' + this.$route.params.companyId
              },
              {
                text: 'Markets',
                disabled: false,
                href: this.$route.fullPath
              }
            ];
            this.childrenTableLoading = false;
          });
          break;
        case 'm':
          dashboardService.facilities(this.$route.params.entityId).then((res) => {
            res.data.facilities.forEach((item) => {
              this.children.push({
                name: item.name,
                entityId: item.coid,
                parentId: item.marketId,
                parentName: this.$route.params.entityName,
                entityType: 'f',
                isVisible: item.isVisible,
                hasChanged: false
              });
            });
            this.headers[0].text = 'Facility Name';
            this.hierarchyLevel = 'Facilities';
            this.breadCrumbItems = [
              {
                text: 'Companies - ' + this.$route.params.company,
                disabled: false,
                href: '/Admin'
              },
              {
                text: 'Groups - ' + this.$route.params.group,
                disabled: false,
                href: '/Admin/c/' + this.$route.params.companyId + '/' + this.formatLinkName(this.$route.params.company)
              },
              {
                text: 'Divisions - ' + this.$route.params.division,
                disabled: false,
                href: '/Admin/g/' + this.$route.params.groupId + '/' + this.formatLinkName(this.$route.params.group) + '/' + this.formatLinkName(this.$route.params.company) + '/' + this.$route.params.companyId
              },
              {
                text: 'Markets - ' + this.$route.params.entityName,
                disabled: false,
                href: '/Admin/d/' + this.$route.params.divisionId + '/' + this.formatLinkName(this.$route.params.division) + '/' + this.formatLinkName(this.$route.params.company) + '/' + this.$route.params.companyId + '/' + this.formatLinkName(this.$route.params.group) + '/' + this.$route.params.groupId
              },
              {
                text: 'Facilities',
                disabled: false,
                href: this.$route.fullPath
              }
            ];
            this.childrenTableLoading = false;
          });
      }
    },
    formatLinkName (name) {
      return name.replace(/\//g, '%2F');
    },
    back () {
      this.$router.back();
    },
    updateChangeCount (hasChanged) {
      this.saveChangesSuccessful = false;
      if (hasChanged === true) {
        this.changeCount++;
      } else if (hasChanged === false && this.changeCount > 0) {
        this.changeCount--;
      }
    },
    saveIsVisibleChanges () {
      this.changesLoading = true;
      const changedMembers = this.children.filter(member => member.hasChanged === true);
      const bodyFormData = new FormData();
      changedMembers.forEach((member) => {
        const formObject = {
          EntityID: member.entityId.toString(),
          EntityType: member.entityType,
          UpdateIsVisible: member.isVisible
        };
        bodyFormData.append('changedMembers[]', JSON.stringify(formObject));
      });
      adminService.updateIsVisible(bodyFormData).then((res) => {
        if (res.status === 200) {
          this.children.forEach(member => {
            member.hasChanged = false;
            this.changesLoading = false;
            this.saveChangesSuccessful = true;
          });
          this.changeCount = 0;
        }
      }, (reason) => {
        this.changesLoading = false;
        this.saveChangesError = reason;
      });
    },
    cancelIsVisibleChanges () {
      this.cancelChangesLoading = true;
      const changedMembers = this.children.filter(member => member.hasChanged === true);
      changedMembers.forEach((member) => {
        member.hasChanged = !member.hasChanged;
        if (this.changeCount > 0) {
          this.changeCount--;
        }
      });
      this.getChildrenInfo();
    },
    nameFilter (value) {
      value = value.toString().toLocaleUpperCase();
      var searchString = this.nameSearch.toString().toLocaleUpperCase();

      return value != null &&
          searchString != null &&
          typeof value === 'string' &&
          value.indexOf(searchString) !== -1;
    },
    entityIdFilter (value) {
      value = value.toString().toLocaleUpperCase();
      var searchString = this.entityIdSearch.toString().toLocaleUpperCase();
      if (!searchString) {
        return true;
      }
      return (value != null &&
          searchString != null &&
          typeof value === 'string' &&
          value.indexOf(searchString) === 0
      );
    },
    exportVisible () {
      adminService.getAllVisible().then((res) => {
        var data = res.data.visibleEntities;
        const date = new Date();
        try {
          json2excel({
            data,
            name: 'Visible Entities' + '_' +
              (date.getMonth() + 1) +
              '_' +
              date.getDate() +
              '_' +
              date.getFullYear()
          });
        } catch (e) {
          console.error('Error exporting all visible entities', e);
        }
      });
    }
  }
};

</script>

<style scoped lang="scss">
#admin-header {
  font-size: 52px;
  font-weight: 700;
  line-height: 52px;
  letter-spacing: -1.7px;
  text-align: center;
  color: #1E345D;
}
#page-header {
  background: white;
}
.horizontal-divider {
  width: 80px;
  height: 4px;
  margin: auto;
  margin-bottom: 10px;
  /* Orange on Light */
  background: #DB7100;
}
.section-header {
  background-color: #1E345D;
  color: white;
}
.dropdown-item{
  font-family: Roboto;

  &.last-or-middle{
    border-top : 1px solid rgba(0, 0, 0, 0.12);
  }
}
.back-btn{
  border-radius: 100%;
}
::v-deep #member-settings-table{
  .v-data-table-header {
    th {
      background-color: $blue;
      color: white !important;
      font-weight: 700;
      font-size: 15px;
      white-space: nowrap;
      padding: 15px;
      border-left: 1px solid #dddddd;
      border-right: 1px solid #dddddd;
    }
  }
  td {
    padding-top: 10px;
    padding-bottom: 5px;
    border-left: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
  }
  .v-data-table-header-mobile__wrapper {
    .v-input__slot {
      color: white !important;
    }
    .v-label {
      color: white !important;
    }
  }
  .v-data-table__mobile-table-row {
    border-top: 5px solid #dddddd;
  }
}
.checkbox-container {
  display: inline-block;
}
::v-deep .v-data-table-header__icon {
  color: white !important;
}
.member-settings-item {
  pointer-events: auto;
  cursor: pointer !important;
}
.submit-btn {
  background-color: #1e345d !important;
  color: white;
}
.hierarchy-breadcrumbs {
  margin-top: -1%;
  margin-bottom: -2%;
  overflow-y: auto;
}
.hierarchy-level {
  text-align: center;
  color:#1E345D;
  margin-top: -0.5%;
}
</style>
