<template>
  <highcharts
    :constructorType="'mapChart'"
    class="hc"
    :options="chartOptions"
    ref="chart"
    >
  </highcharts>
</template>

<script>
import HighCharts from 'highcharts';
import Map from 'highcharts/modules/map';
import MarkerClusters from 'highcharts/modules/marker-clusters';

Map(HighCharts);
MarkerClusters(HighCharts);

export default {
  props: { chartData: Array },
  data () {
    const usMap = require('@highcharts/map-collection/countries/us/us-all.topo.json');
    return {
      chartOptions: {
        chart: {
          map: usMap,
          height: (9 / 16 * 100) + '%'
        },
        title: {
          text: ''
        },
        credits: {
          enabled: false
        },
        subtitle: {
          text: ''
        },
        mapNavigation: {
          enabled: true,
          buttonOptions: {
            alignTo: 'spacingBox'
          },
          enableMouseWheelZoom: false
        },
        legend: {
          enabled: false
        },
        colorAxis: {
          min: 1,
          type: 'logarithmic',
          minColor: '#EEEEFF',
          maxColor: '#000022',
          stops: [
            [0, '#EFEFFF'],
            [0.67, '#4444FF'],
            [1, '#000022']
          ]
        },
        tooltip: { enabled: true },
        series: [{
          animation: {
            duration: 1000
          },
          data: this.chartData,
          joinBy: ['postal-code', 'code'],
          dataLabels: {
            enabled: true,
            color: '#FFFFFF',
            format: '{point.count}'
          },
          name: '',
          useHTML: true,
          tooltip: {
            headerFormat: undefined,
            pointFormat: '<b>State: </b>{point.code}<br><b>Hosp: </b>{point.hospCount}<br><b>ASC: </b>{point.ascCount}'
          }
        }]
      }
    };
  }
};
</script>
