import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import runtimeConfig from '../../runtimeConfig';

const appInsightsKey = runtimeConfig.INSIGHTS_KEY;

const LoadApplicationInsights = () => {
  if (appInsightsKey) {
    const appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: appInsightsKey
      }
    });

    appInsights.loadAppInsights();
    appInsights.trackPageView();
  }
};

export {
  LoadApplicationInsights
};
