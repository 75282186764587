import dashboardService from './dashboard.service';
import adminService from './admin.service';

const services = {
  Dashboard: dashboardService,
  Admin: adminService
};

export default class {
  get (name) {
    return services[name];
  }
}
