<template>
  <div class="header-div">
    <h5
      class="justify-center revenue-label"
      v-show="this.showRestrictedCompanyFields"
    >
      <v-avatar size="25"><v-img src="../assets/lock2.png" /></v-avatar>
      HealthTrust Revenue:
      <span class="amount" v-if="this.revenue">
        {{ this.hiddenRevenue }}
        <v-icon v-if="this.revenue" class="eyecon" color="white" @click="hideShowRevenue()">{{ eyeCon }}</v-icon>
      </span>
      <v-tooltip max-width="500" right>
        <template v-slot:activator="{ on, attrs }">
          <v-icon small color="white" class="mb-2 ml-1" v-bind="attrs" v-on="on"
            >mdi-information-outline</v-icon
          >
        </template>
        <span>
          The HealthTrust Revenue value is for the trailing 12 months (TTM
          <span v-if="this.startDate != null && this.endDate != null"
            >{{ this.startDate }} - {{ this.endDate }}</span
          >
          <span v-else>Dates Unavailable</span>). The calculation will be
          refined to be more comprehensive once additional financial data is
          available in Power BI. Source: This information is currently loaded to
          the dashboard manually via spreadsheet. <br/>
          <b>Included:</b><br/>
          <ul>
            <li>- Admin fees</li>
            <li>- Global sourcing fees</li>
            <li>- Retained Spend</li>
            <ul>
              <li>&#8226; Pharmacy Benefit Management (PBM)</li>
              <li>&#8226; HR Services</li>
            </ul>
            <li>- SaaS - MDM Dashboards</li>
            <li>- SOW Work</li>
            <ul>
              <li>&#8226; Supply Chain Consulting</li>
              <li>&#8226; Purchased Services Consulting</li>
              <li>&#8226; MDM Sourcing</li>
              <li>&#8226; MDM Dashboards - Implementation fees</li>
              <li>&#8226; Leadership staffing</li>
              <li>&#8226; Outsource supply chains</li>
              <li>&#8226; Data-as-a-Service type work, such as item master cleansing, when applicable</li>
            </ul>
          </ul><br/>
          <b>NOT included:</b><br/>
          <ul>
            <li>- Product sales (CPS and Regard)</li>
            <li>- SaaS - Valify technology subscription</li>
            <li>- Valify implementation fees</li>
            <li>- Workforce</li>
          </ul>
        </span>
      </v-tooltip>
    </h5>
    <h5 class="revenue-label">
      Spend through GPO:
      <span
        v-if="this.healthTrustSpend !== null"
        class="amount"
      >
        {{ this.healthTrustSpend }}
      </span>
      <span
        v-else
        class="amount"
      >
        Awaiting Data Feed from Member
      </span>
      <v-tooltip max-width="500" top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon small color="white" class="mb-2 ml-1" v-bind="attrs" v-on="on"
            >mdi-information-outline</v-icon
          >
        </template>
        <span>
          The trailing twelve months (TTM <span v-if="this.gpoStart != null && this.gpoEnd != null"
            >{{ this.gpoStart }} - {{ this.gpoEnd }}</span
            >
            <span v-else>Dates Unavailable</span>) of spend through the GPO. Source: RAFT Allocated Fees & Retained Spend
        </span>
      </v-tooltip>
    </h5>
  </div>
</template>

<script>
export default {
  props: {
    revenue: Number,
    spend: Number,
    showRestrictedCompanyFields: Boolean,
    startDate: String,
    endDate: String,
    gpoStart: String,
    gpoEnd: String
  },
  data () {
    return {
      healthTrustRevenue: null,
      hiddenRevenue: null,
      healthTrustSpend: null,
      showRestricted: null,
      eyeCon: 'mdi-eye'
    };
  },
  mounted () {
    this.getRevenueAndSpend();
  },
  methods: {
    year () {
      const date = new Date();
      return date.getFullYear();
    },
    getRevenueAndSpend () {
      const USDollar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0
      });

      if (this.revenue != null && this.revenue > 0) {
        this.healthTrustRevenue = USDollar.format(this.revenue);
        this.hiddenRevenue = '\u25CF\u25CF\u25CF\u25CF\u25CF';
      } else {
        this.healthTrustRevenue = '';
      }
      if (this.spend != null && this.spend > 0) {
        this.healthTrustSpend = USDollar.format(this.spend);
      } else {
        this.healthTrustSpend = null;
      }
      this.showRestricted = this.showRestrictedCompanyFields;
    },
    hideShowRevenue () {
      if (this.eyeCon === 'mdi-eye') {
        this.hiddenRevenue = this.healthTrustRevenue;
        this.eyeCon = 'mdi-eye-off';
      } else {
        this.hiddenRevenue = '\u25CF\u25CF\u25CF\u25CF\u25CF';
        this.eyeCon = 'mdi-eye';
      }
    }
  }
};
</script>

<style lang="scss">
.header-div {
  background-color: $blue;
  justify-content: center;
}
.amount {
  color: white;
  text-align: center;
  font-weight: bold;
}
.revenue-label {
  color: white;
  text-align: center;
  font-weight: bold;
  justify-content: center;
}
.eyecon {
  margin-left: 5px;
  margin-right: 5px;
}
</style>
