<template>
  <article class="landing-page">
    <header class="page-header">
      <figure class="header-body">
        <section class="header-title">
          <p class="body-title">Welcome to the</p>
          <p class="body-title">Executive Dashboard!</p>
        </section>
        <section class="header-button">
          <v-btn class="button" :class="{ 'signing-in': isSigningIn }" @click="doSignIn()">
            <figure v-if="isSigningIn" class="loading">
              <v-progress-circular size="25" indeterminate class="load"/>
              <span class="section-text">Signing In</span>
            </figure>
            <span v-else>
              Sign In
              <v-icon class="login-icon">mdi-login</v-icon>
            </span>
          </v-btn>
        </section>
      </figure>
    </header>

    <section class="content-mission">
      <p class="mission-title-top">OUR MISSION</p>
      <p class="mission-title-mid">Performance Improvement for Healthcare</p>
      <div class="horizontal-divider"></div>
      <section class="mission-body-container">
        <div class="placeholder"></div>
        <p class="mission-body">
          Strengthening provider performance and clinical excellence through an aligned membership
          model and the delivery of total spend management advisory solutions that leverage our
          operator experience, scale, and innovation.
        </p>
        <div class="placeholder"></div>
      </section>
    </section>
  </article>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';

export default {
  data () {
    return {
      connectLink: 'https://healthtrustpg.com/become-a-healthtrust-member/',
      learnLink: 'https://healthtrustpg.com/about-healthtrust/',
      user: ''
    };
  },
  computed: {
    ...mapGetters({
      isSigningIn: 'getIsSigningIn'

    }),
    signInText () {
      if (this.isSigningIn) {
        return 'Signing In...';
      }
      return 'SIGN IN';
    }
  },
  methods: {
    ...mapMutations({
      setIsSigningIn: 'setIsSigningIn'
    }),
    ...mapActions({
      signIn: 'signIn'
    }),
    goToLink (linkType) {
      window.location.href = `${this[linkType]}`;
    },
    doSignIn () {
      this.signIn(window.location.href);
    }
  }
};
</script>
<style lang=scss>
  .landing-page {
    height: 100%;
    font-family: 'Roboto';

    .page-header {
      background-image: url(/images/landingHero.jpg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      background-blend-mode: overlay;
      color: #fff;
      min-height: 21.5em;

      .header-body {
        padding: 4.5em 2em 2em 3em;

        p {
          line-height: 1em;
        }

        .header-title {

         .body-title {
            color: $blue;
            margin-bottom: 0;
            font-size: 3.25em;
            font-weight: bold;
          }
        }

        .header-button {

          .button {
            width: 8em;
            height: 3.0625em;
            background-color: $orange--background-button;
            color: #fff;
            font-size: 1.0625em;
            letter-spacing: 1px;
            color: black;
            margin-top: 2em;

            &.signing-in {
              width: 10em;
            }

            .login-icon {
              padding-left: 0.25em;
            }

            .loading {

              .section-text {
                padding-left: 0.5em;
              }
            }
          }
        }
      }
    }

    .content-mission {
      padding: 3.5em 2em 5em;
      text-align: center;
      background-color: white;

      p {
        margin: 0;
      }

      .mission-title-top {
        padding-top: 1em;
        font-size: 0.875em;
        font-weight: 500;
        letter-spacing: 4px;
        color: $orange--text;
        margin-bottom: 0.25em;
      }

      .mission-title-mid {
        font-size: 2.5em;
        font-weight: 700;
        color: $blue;
        padding-bottom: 0.5em;
      }

      .horizontal-divider {
        width: 80px;
        height: 4px;
        margin: auto;
        /* Orange on Light */
        background: #DB7100;
      }

      .mission-body-container {
        display: flex;
        flex-direction: row;

        .placeholder {
          width: 55%;
        }

        .mission-body {
          padding-top: 1em;
          font-size: 0.9375em;
          font-weight: 400;
          color: $grey--text;
        }
      }
    }
  }
</style>
