import Vue from 'vue';
import Vuex from 'vuex';
import messages from '@modules/messages';
import dashboardItems from '@modules/dashboardItems';
import passRoles from '../utilities/roles';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    requiredRole: passRoles.ed,
    isSigningIn: '',
    user: {},
    userOrganization: {},
    isLanding: false,
    isDashboard: false,
    featureFlags: {}
  },
  getters: {
    getIsSigningIn (state) {
      return state.isSigningIn;
    },
    getUser (state) {
      return state.user;
    },
    getIsLanding (state) {
      return state.isLanding;
    },
    getIsDashboard (state) {
      return state.isDashboard;
    },
    getUserOrganization (state) {
      return state.userOrganization;
    },
    getRequiredRole (state) {
      return state.requiredRole;
    },
    getFeatureFlag: (state) => (name) => {
      return state.featureFlags[name];
    }
  },
  mutations: {
    syncLocalStorage (state) {
      const localStorageStore = JSON.parse(localStorage.getItem('me-store'));
      if (localStorageStore) {
        this.replaceState({ ...state, ...localStorageStore });
      }
    },
    setUser (state, payload) {
      state.user = { ...payload };
    },
    setIsSigningIn (state, payload) {
      state.isSigningIn = payload;
    },
    setIsLanding (state, payload) {
      state.isLanding = payload;
    },
    setIsDashboard (state, payload) {
      state.isDashboard = payload;
    },
    setUserOrganization (state, payload) {
      state.userOrganization = { ...state.userOrganization, ...payload };
    },
    setFeatureFlags (state, payload) {
      state.featureFlags = { ...payload };
    }
  },
  actions: {
    initializeStore ({ commit }) {
      commit('syncLocalStorage');
    },
    async signIn ({ commit, dispatch }, href) {
      commit('setIsSigningIn', true);
      var targetUrl = { redirectAfterLogin: href };
      await this._vm.$userManager.signinRedirect({ state: targetUrl, useReplaceToNavigate: true });
    },
    async signOut ({ commit }) {
      await this._vm.$userManager.signoutRedirectCallback();
      await this._vm.$userManager.removeUser();
    },
    clearUser ({ commit }) {
      commit('setUser', {});
      commit('setUserOrganization', {});
    },
    fetchFeatureFlags ({ commit }) {
      const featureFlagsManager = Vue.prototype.$featureFlagsManager;
      featureFlagsManager.on('ready', () => {
        commit('setFeatureFlags', featureFlagsManager.allFlags());
      });

      featureFlagsManager.on('change', () => {
        commit('setFeatureFlags', featureFlagsManager.allFlags());
      });
    }
  },
  modules: {
    messages,
    dashboardItems
  }
});

store.subscribe((mutation, state) => {
  // excluding Black Opps store from local storage sync
  const meState = (({ dashboardItems, filterSidebar, ...s }) => s)(state);
  localStorage.setItem('me-store', JSON.stringify(meState));
});
export default store;

store.dispatch('initializeStore');
