<template>
  <article>
    <header id="page-header" class="pt-10 pb-1 mb-5">
      <p id="admin-header">Admin Operations</p>
      <div class="horizontal-divider"></div>
      <v-tabs
        background-color="#1E345D"
        dark
        color="white"
        fixed-tabs
        v-model="tab"
      >
        <v-tabs-slider color="#DB7100"></v-tabs-slider>
        <v-tab>
          Operations
        </v-tab>
        <v-tab>
          Log Reports
        </v-tab>
        <v-tab>
          Member Settings
        </v-tab>
      </v-tabs>
    </header>
    <section v-show="tab == 0">
      <v-container>
        <v-row class="px-3 pb-3">
          <v-col md="1" xs="12">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="back-btn"
                  @click="back()"
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon large color="#1E345D">
                    mdi-arrow-left-circle-outline
                  </v-icon>
                </v-btn>
              </template>
              BACK
            </v-tooltip>
          </v-col>
          <v-col md="10" cols="12">
            <v-card>
              <v-data-table
                id="excel-table"
                hide-default-footer
                :headers="excelHeaders"
                :items="excelItems"
                dense
              >
                <template v-slot:item.label="{ item }">
                  <v-file-input
                    v-model="importFile"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    :label="item.label"
                  >
                    <template slot="append-outer">
                      <v-btn
                        :disabled="item.disabled"
                        elevation="1"
                        @click="importExcelFile()"
                        >{{ item.btnLabel }}</v-btn
                      >
                    </template>
                  </v-file-input>
                  <v-col cols="12" md="4" offset-md="2">
                    <v-btn
                      class="mr-5"
                      elevation="1"
                      target="_blank"
                      href="https://hcahealthcare.sharepoint.com/sites/HPG-ITG/ProductTechnology/CustomerSolutions/Documentation%20Archive/Forms/AllItems.aspx?csf=1&web=1&e=z2sZRq&cid=750ebb9a%2D1f1b%2D4680%2D9c52%2D7f1c37dfa294&FolderCTID=0x012000C5ADC94E470286419821E0EB4352EA5E&id=%2Fsites%2FHPG%2DITG%2FProductTechnology%2FCustomerSolutions%2FDocumentation%20Archive%2FExecutive%20Dashboard%2FExcel%20Upload%20Template&viewid=6e00b915%2Df6eb%2D4a8d%2D8f38%2D1eb9abfcd61b"
                    >
                      Excel template folder &nbsp;
                      <v-icon>mdi-open-in-new</v-icon>
                    </v-btn>
                  </v-col>
                </template>
                <template v-slot:item.error="{ item }">
                  <v-progress-circular
                    color="#DB7100"
                    v-if="item.loading === true"
                    indeterminate
                  ></v-progress-circular>
                  <v-icon v-if="item.icon === true" color="green"
                    >mdi-check-bold</v-icon
                  >
                  <span>{{ item.error }}</span>
                </template>
                <template v-slot:item.lastUpdated="{ item }">
                  <v-progress-circular
                    color="#DB7100"
                    v-if="item.dateLoading === true"
                    indeterminate
                  ></v-progress-circular>
                  <span v-if="item.lastUpdated">{{ item.lastUpdated }}</span>
                  <span>{{ item.dateError }}</span>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="px-3">
          <v-col offset-md="1" md="10" cols="12">
            <v-card>
              <v-data-table
                id="edw-table"
                hide-default-footer
                :headers="edwHeaders"
                :items="edwItems"
                dense
              >
                <template v-slot:item.table="{ item }">
                  <v-btn
                    :disabled="item.disabled"
                    elevation="1"
                    @click="updateEDW()"
                    >Update {{ item.table }}</v-btn
                  >
                </template>
                <template v-slot:item.error="{ item }">
                  <v-progress-circular
                    color="#DB7100"
                    v-if="item.loading === true"
                    indeterminate
                  ></v-progress-circular>
                  <v-icon v-if="item.icon === true" color="green"
                    >mdi-check-bold</v-icon
                  >
                  <span>{{ item.error }}</span>
                </template>
                <template v-slot:item.lastUpdated="{ item }">
                  <v-progress-circular
                    color="#DB7100"
                    v-if="item.dateLoading === true"
                    indeterminate
                  ></v-progress-circular>
                  <span v-if="item.lastUpdated">{{ item.lastUpdated }}</span>
                  <span>{{ item.dateError }}</span>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="px-3">
          <v-col offset-md="1" md="10" cols="12">
            <v-card>
              <v-card-title class="section-header"> Salesforce </v-card-title>
              <v-list>
                <v-list-item class="dropdown-item justify-center">
                  <span class="font-weight-bold">Last Updated: &nbsp; </span>
                  <span v-if="salesforceLastUpdated">{{
                    this.salesforceLastUpdated
                  }}</span>
                  <span v-if="salesforceLoading === true">
                    <v-progress-circular
                      color="#DB7100"
                      indeterminate
                    ></v-progress-circular>
                  </span>
                  <span v-if="salesforceError">
                    {{ this.salesforceError }}</span
                  >
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="px-3 pb-3">
          <v-col offset-md="1" md="10" cols="12">
            <v-card>
              <v-card-title class="section-header"> Stage to Base </v-card-title>
              <v-list>
                <v-list-item class="dropdown-item justify-center">
                  <span class="font-weight-bold">Last Updated: &nbsp; </span>
                  <span v-if="stageToBaseLastUpdated">{{
                    this.stageToBaseLastUpdated
                  }}</span>
                  <span v-if="stageToBaseLoading === true">
                    <v-progress-circular
                      color="#DB7100"
                      indeterminate
                    ></v-progress-circular>
                  </span>
                  <span v-if="stageToBaseError">
                    {{ this.stageToBaseError }}</span
                  >
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <section v-show="tab == 1">
      <v-container>
        <v-row class="px-3">
          <v-col md="1" xs="12">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="back-btn"
                  @click="back()"
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon large color="#1E345D">
                    mdi-arrow-left-circle-outline
                  </v-icon>
                </v-btn>
              </template>
              BACK
            </v-tooltip>
          </v-col>
          <v-col cols="12" md="2">
            <v-select
              :items="messageTypes"
              dense
              label="Log Message Type"
              v-model="logType"
            ></v-select>
          </v-col>
          <v-col cols="12" md="2">
            <v-text-field dense label="User Name" v-model="logSearchName">
            </v-text-field>
          </v-col>
          <v-col cols="12" md="2">
            <v-text-field
              type="date"
              dense
              label="Login Report Start Date"
              v-model="logStart"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="2">
            <v-text-field
              type="date"
              dense
              label="Login Report End Date"
              v-model="logEnd"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="2">
            <v-btn
              class="submit-btn"
              block
              elevation="2"
              :disabled="
                logStart == null ||
                logStart === '' ||
                logEnd == null ||
                logEnd === '' ||
                logType === ''
              "
              @click="getLogReport(logType, logSearchName, logStart, logEnd)"
            >
              Submit
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="px-3">
          <v-col cols="12" md="10" offset-md="1">
            <v-data-table
              :headers="logHeaders"
              :items="logs"
              :loading="logsLoading"
              id="logs-table"
            >
              <template v-slot:item.dateCreated="{ item }">
                <span>{{ new Date(item.dateCreated + 'Z') }}</span>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-row class="px-3">
          <v-col offset-md="1">
            <v-btn @click="extractLogs()" class="submit-btn">
              Export Logs to Excel
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <section v-show="tab == 2">
      <v-container>
        <v-row class="px-3 pb-3">
          <v-col md="1" xs="12">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="back-btn"
                  @click="back()"
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon large color="#1E345D">
                    mdi-arrow-left-circle-outline
                  </v-icon>
                </v-btn>
              </template>
              BACK
            </v-tooltip>
          </v-col>
          <v-col cols="12" md="10">
            <v-card>
              <v-data-table
                height="650"
                id="member-settings-table"
                fixed-header
                dense
                item-key="companyId"
                :footer-props="{
                  'items-per-page-options': [10, 25, 50, 100]
                }"
                :loading="companiesTableLoading"
                :headers="headers"
                :items="companies"
              >
                <template v-slot:top>
                  <v-container>
                    <v-row>
                      <v-col>
                        <v-text-field
                          v-model="nameSearch"
                          label="Search Members by Name"
                          class="mx-4"
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <v-text-field
                          v-model="companyIDSearch"
                          label="Search Members by Company ID"
                          class="mx-4"
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <v-text-field
                          v-model="gpoidSearch"
                          label="Search Members by GPOID"
                          class="mx-4"
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <v-text-field
                          v-model="retainedSearch"
                          label="Search Members by Retained GPOID"
                          class="mx-4"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </template>
                <template v-slot:item.name="{ item }">
                  <v-list-item v-if="changeCount === 0"
                    class="member-settings-item"
                    :href="item.link"
                  >
                    {{ item.name }} &nbsp;
                    <v-btn
                      icon
                      :href="'/Dashboard/c/' + item.companyId + '/' + formatLinkName(item.name)"
                    >
                      <v-icon color="#1e345d">mdi-open-in-new</v-icon>
                    </v-btn>
                  </v-list-item>
                  <v-list-item v-else
                    class="member-settings-item"
                  >
                    {{ item.name }} &nbsp;
                    <v-btn
                      disabled
                      icon
                      :href="'/Dashboard/c/' + item.companyId + '/' + item.name"
                    >
                      <v-icon color="#1e345d">mdi-open-in-new</v-icon>
                    </v-btn>
                  </v-list-item>
                </template>
                <template v-slot:item.isVisible="{ item }">
                  <div class="checkbox-container">
                    <v-checkbox
                      color="#DB7100"
                      hide-details
                      @change="item.hasChanged = !item.hasChanged"
                      @click="updateChangeCount(item.hasChanged)"
                      v-model="item.isVisible"
                    ></v-checkbox>
                  </div>
                </template>
                <template v-slot:item.retainedIDs="{ item }">
                  <v-row>
                    <v-col v-if="item.retainedIDs.length > 0">
                      <v-list-item
                        dense
                        v-for="(id, i) in item.retainedIDs"
                        :key="i"
                      >
                        {{ id }}
                      </v-list-item>
                    </v-col>
                    <v-col>
                      <RetainedGPOID
                        v-if="item.retainedIDs.length > 0"
                        class="mt-1"
                        :name="item.name"
                        :company-id="item.companyId"
                        :gpoid="item.gpoid"
                        :member-retained-ids="item.retainedIDs"
                        :retained-ids="retainedIds"
                        :icon="'mdi-pencil'"
                        :admin-page-changes="changeCount"
                        @modal-refresh="getCompanyInfo"
                      />
                      <RetainedGPOID
                        v-else
                        :name="item.name"
                        :company-id="item.companyId"
                        :gpoid="item.gpoid"
                        :member-retained-ids="item.retainedIDs"
                        :retained-ids="retainedIds"
                        :icon="'mdi-plus'"
                        :admin-page-changes="changeCount"
                        @modal-refresh="getCompanyInfo"
                      />
                    </v-col>
                  </v-row>
                </template>
              </v-data-table>
            </v-card>
            <br />
            <v-row>
              <v-col cols="12" lg="3" md="3" xl="2">
                <v-btn
                  class="submit-btn"
                  :disabled="changeCount === 0"
                  @click="saveIsVisibleChanges()"
                >
                  Save Changes &nbsp;
                  <v-icon v-if="this.changesLoading === false">mdi-content-save</v-icon>
                  <v-progress-circular
                    v-if="this.changesLoading === true"
                    color="#DB7100"
                    indeterminate
                  />
                </v-btn>
                &nbsp;
                <span>
                  <v-icon
                    v-if="this.saveChangesSuccessful === true && this.changeCount === 0"
                    color="green"
                    >mdi-check-bold</v-icon
                  >
                  <span v-if="this.saveChangesError != ''">{{
                    this.saveChangesError
                  }}</span>
                </span>
              </v-col>
              <v-col>
                <CancelChanges
                  @cancel-changes="cancelIsVisibleChanges"
                  :change-count="changeCount"
                />
              </v-col>
              <v-spacer/>
              <v-col>
                <v-btn
                  class="submit-btn"
                  @click="exportVisible"
                  :disabled="companiesTableLoading"
                >
                  Export Visible Entities &nbsp; <v-icon>mdi-file-excel</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </article>
</template>

<script>
import adminService from '../services/admin.service';
import dashboardService from '../services/dashboard.service';
import { json2excel } from 'js2excel';
import RetainedGPOID from '../components/RetainedGPOID.vue';
import CancelChanges from '../components/CancelChanges.vue';

export default {
  components: { RetainedGPOID, CancelChanges },
  data () {
    return {
      importFile: null,
      salesforceLastUpdated: '',
      salesforceLoading: true,
      salesforceError: '',
      stageToBaseLastUpdated: '',
      stageToBaseLoading: true,
      stageToBaseError: '',
      tab: null,

      companies: [],
      retainedIds: [],
      companiesTableLoading: false,
      changeCount: 0,
      changesLoading: false,
      saveChangesSuccessful: false,
      saveChangesError: '',

      nameSearch: '',
      companyIDSearch: '',
      gpoidSearch: '',
      retainedSearch: '',

      logStart: '',
      logEnd: '',
      logType: '',
      logSearchName: ' ',
      logHeaders: [
        { text: 'Log ID', value: 'logId', sortable: false },
        { text: 'Message', value: 'message', sortable: false },
        { text: 'User Name', value: 'userCreated', sortable: false },
        { text: 'User 3-4 ID', value: 'userID', sortable: false },
        { text: 'Member Name', value: 'name' },
        { text: 'Date', value: 'dateCreated', sortable: false }
      ],
      messageTypes: ['Page view', 'Exception'],
      logs: [],
      logsLoading: false,

      adminTabs: [
        { title: 'Operations' },
        { title: 'Log Reports' },
        { title: 'Member Settings' }
      ],

      edwHeaders: [
        {
          text: 'EDW',
          value: 'table',
          align: 'start',
          sortable: false,
          width: 500
        },
        {
          text: 'Status',
          value: 'error',
          align: 'center',
          sortable: false,
          width: 200
        },
        {
          text: 'Last Updated',
          value: 'lastUpdated',
          align: 'start',
          sortable: false
        }
      ],

      edwItems: [
        {
          table: 'EDW',
          lastUpdated: '',
          icon: false,
          loading: false,
          dateLoading: true,
          error: '',
          dateError: '',
          disabled: false
        }
      ],

      stbHeaders: [
        {
          text: 'Stage to Base',
          value: 'table',
          align: 'start',
          sortable: false,
          width: 500
        },
        {
          text: 'Status',
          value: 'error',
          align: 'center',
          sortable: false,
          width: 200
        },
        {
          text: 'Last Updated',
          value: 'lastUpdated',
          align: 'start',
          sortable: false
        }
      ],

      stbItems: [
        {
          table: 'Stage to Base',
          lastUpdated: '',
          icon: false,
          loading: false,
          dateLoading: true,
          error: '',
          dateError: '',
          disabled: false
        }
      ],

      excelHeaders: [
        {
          text: 'Revenue & KPI Overview Excel File Import',
          value: 'label',
          align: 'start',
          sortable: false,
          width: 500
        },
        {
          text: 'Status',
          value: 'error',
          align: 'center',
          sortable: false,
          width: 200
        },
        {
          text: 'Last Updated',
          value: 'lastUpdated',
          align: 'start',
          sortable: false
        }
      ],

      excelItems: [
        {
          label: 'Select Excel File',
          lastUpdated: '',
          icon: false,
          loading: false,
          btnLabel: 'Import',
          dateLoading: true,
          error: '',
          dateError: '',
          disabled: false
        }
      ]
    };
  },
  computed: {
    headers () {
      return [
        { text: 'Member Name', value: 'name', filter: this.nameFilter },
        { text: 'Is Visible', value: 'isVisible', align: 'center' },
        {
          text: 'Company ID',
          value: 'companyId',
          filter: this.companyIDFilter
        },
        { text: 'GPOID', value: 'gpoid', filter: this.gpoidFilter },
        {
          text: 'Retained GPOID',
          value: 'retainedIDs',
          filter: this.retainedFilter,
          sortable: false
        }
      ];
    }
  },
  mounted () {
    if (sessionStorage.tab) {
      this.tab = parseInt(sessionStorage.getItem('tab'));
    };
    this.getCompanyInfo();
    this.getEDWLastUpdated();
    this.getSalesforceLastUpdated();
    this.getBaseLastUpdated();
    this.getRevenueLastUpdated();
    this.defaultStartDate();
    this.defaultEndDate();
  },
  watch: {
    tab (newTab) {
      sessionStorage.setItem('tab', newTab);
    }
  },
  methods: {
    async getCompanyInfo () {
      this.changeCount = 0;
      this.companies = [];
      this.companiesTableLoading = true;
      adminService.getAllCompanies().then((res) => {
        this.retainedIds = res.data.retainedIDs;
        res.data.companies.forEach((member) => {
          const retainedIDs = res.data.retainedIDs.filter(
            (item) => item.companyId === member.companyId
          );
          this.companies.push({
            companyId: member.companyId,
            name: member.name,
            link: 'Admin/c/' + member.companyId + '/' + this.formatLinkName(member.name),
            isVisible: member.isVisible,
            gpoid: member.gpoid,
            hasChanged: false,
            retainedIDs: retainedIDs.map((item) => item.gpoid)
          });
        });
        this.companiesTableLoading = false;
      });
    },
    formatLinkName (name) {
      return name.replace(/\//g, '%2F');
    },
    updateEDW () {
      this.edwItems[0].error = '';
      this.edwItems[0].loading = true;
      this.edwItems[0].disabled = true;
      this.edwItems[0].icon = false;
      adminService.updateEDWStageTables().then(
        (res) => {
          if (res.status === 200) {
            this.edwItems[0].loading = false;
            this.edwItems[0].icon = true;
            this.edwItems[0].disabled = false;
            this.getEDWLastUpdated();
          }
        },
        (reason) => {
          this.edwItems[0].loading = false;
          this.edwItems[0].disabled = false;
          this.edwItems[0].error = reason;
        }
      );
    },
    runStageToBase () {
      this.stbItems[0].icon = false;
      this.stbItems[0].loading = true;
      this.stbItems[0].disabled = true;
      this.stbItems[0].error = '';
      dashboardService.stageToBase().then(
        (res) => {
          if (res.status === 200) {
            this.stbItems[0].loading = false;
            this.stbItems[0].icon = true;
            this.stbItems[0].disabled = false;
            this.getBaseLastUpdated();
          }
        },
        (reason) => {
          this.stbItems[0].loading = false;
          this.stbItems[0].disabled = false;
          this.stbItems[0].error = reason;
        }
      );
    },
    getEDWLastUpdated () {
      adminService.getEDWLastUpdated().then(
        (res) => {
          this.edwItems[0].lastUpdated = '';
          this.edwItems[0].dateError = '';
          this.edwItems[0].dateLoading = true;
          if (res.status === 200) {
            let edwString = res.data.edwDate;
            edwString = edwString + 'Z';
            this.edwItems[0].dateLoading = false;
            this.edwItems[0].lastUpdated = new Date(edwString);
          }
        },
        (reason) => {
          this.edwItems[0].dateLoading = false;
          this.edwItems[0].dateError = reason;
        }
      );
    },
    getSalesforceLastUpdated () {
      this.salesforceLastUpdated = '';
      this.salesforceError = '';
      this.salesforceLoading = true;
      adminService.getSalesforceLastUpdated().then(
        (res) => {
          if (res.status === 200) {
            this.salesforceLoading = false;
            let salesforceString = res.data.salesforceLastUpdated;
            salesforceString = salesforceString + 'Z';
            this.salesforceLastUpdated = new Date(salesforceString);
          }
        },
        (reason) => {
          this.salesforceLoading = false;
          this.salesforceError = reason;
        }
      );
    },
    getBaseLastUpdated () {
      this.stageToBaseLastUpdated = '';
      this.stageToBaseError = '';
      this.stageToBaseLoading = true;
      adminService.getBaseLastUpdated().then(
        (res) => {
          if (res.status === 200) {
            this.stageToBaseLoading = false;
            let stbString = res.data.baseLastUpdated;
            stbString = stbString + 'Z';
            this.stageToBaseLastUpdated = new Date(stbString);
          }
        },
        (reason) => {
          this.stageToBaseLoading = false;
          this.stageToBaseError = reason;
        }
      );
    },
    back () {
      this.$router.push({ path: sessionStorage.getItem('beforeAdmin').toString() });
    },
    getRevenueLastUpdated () {
      adminService.getRevenueLastUpdated().then(
        (res) => {
          this.excelItems[0].lastUpdated = '';
          this.excelItems[0].dateError = '';
          this.excelItems[0].dateLoading = true;
          if (res.status === 200) {
            let revKPIString = res.data.revenueKPIDate;
            revKPIString = revKPIString + 'Z';
            this.excelItems[0].dateLoading = false;
            this.excelItems[0].lastUpdated = new Date(revKPIString);
          }
        },
        (reason) => {
          this.excelItems[0].dateLoading = false;
          this.excelItems[0].dateError = reason;
        }
      );
    },
    importExcelFile () {
      if (this.importFile !== null) {
        this.excelItems[0].disabled = true;
        this.excelItems[0].error = '';
        this.excelItems[0].icon = false;
        this.excelItems[0].loading = true;

        var formData = new FormData();
        formData.append('importFile', this.importFile);
        adminService.importRevenue(formData).then(
          (res) => {
            if (res.status === 200) {
              this.excelItems[0].loading = false;
              this.excelItems[0].icon = true;
              this.excelItems[0].disabled = false;
              this.getRevenueLastUpdated();
            }
          },
          (reason) => {
            this.excelItems[0].loading = false;
            this.excelItems[0].disabled = false;
            this.excelItems[0].error = reason;
          }
        );
      }
    },
    getLogReport (messageType, searchName, startDate, endDate) {
      this.logsLoading = true;
      var searchDate = new Date(startDate);
      var searchEndDate = new Date(endDate);
      const tzOffset = searchDate.getTimezoneOffset();
      searchDate = new Date(
        searchDate.getTime() + tzOffset * 60000
      ).toISOString();
      searchEndDate = new Date(
        searchEndDate.getTime() + tzOffset * 60000
      ).toISOString();
      dashboardService
        .getLogReport(messageType, searchDate, searchEndDate, searchName)
        .then((res) => {
          this.logs = res.data.logEntries;
          this.logsLoading = false;
        });
    },
    extractLogs () {
      var data = this.logs;
      const date = new Date();
      if (data != null && data !== '' && data.length > 0) {
        try {
          json2excel({
            data,
            name:
              'ExecDash_Log' +
              (date.getMonth() + 1) +
              '_' +
              date.getDate() +
              '_' +
              date.getFullYear() +
              '_' +
              date.getHours() +
              '_' +
              date.getMinutes()
          });
        } catch (e) {
          console.error('export error', e);
        }
      }
    },
    defaultStartDate () {
      const date = new Date();
      date.setMonth(date.getMonth() - 1);

      const dateString = date.toISOString().split('T')[0];
      this.logStart = dateString;
    },
    defaultEndDate () {
      const date = new Date();
      const dateString = date.toISOString().split('T')[0];
      this.logEnd = dateString;
    },
    updateChangeCount (hasChanged) {
      this.saveChangesSuccessful = false;
      if (hasChanged === true) {
        this.changeCount++;
      } else if (hasChanged === false && this.changeCount > 0) {
        this.changeCount--;
      }
    },
    saveIsVisibleChanges () {
      this.changesLoading = true;
      const changedMembers = this.companies.filter(member => member.hasChanged === true);
      const bodyFormData = new FormData();
      changedMembers.forEach((member) => {
        const formObject = {
          EntityID: member.companyId.toString(),
          EntityType: 'c',
          UpdateIsVisible: member.isVisible
        };
        bodyFormData.append('changedMembers[]', JSON.stringify(formObject));
      });
      adminService.updateIsVisible(bodyFormData).then((res) => {
        if (res.status === 200) {
          this.companies.forEach(member => {
            member.hasChanged = false;
            this.changesLoading = false;
            this.saveChangesSuccessful = true;
          });
          this.changeCount = 0;
        }
      }, (reason) => {
        this.changesLoading = false;
        this.saveChangesError = reason;
      });
    },
    cancelIsVisibleChanges () {
      this.cancelChangesLoading = true;
      const changedMembers = this.companies.filter(
        (member) => member.hasChanged === true
      );
      changedMembers.forEach((member) => {
        member.hasChanged = !member.hasChanged;
        if (this.changeCount > 0) {
          this.changeCount--;
        }
      });
      this.getCompanyInfo();
    },
    nameFilter (value) {
      value = value.toString().toLocaleUpperCase();
      var searchString = this.nameSearch.toString().toLocaleUpperCase();

      return (
        value != null &&
        searchString != null &&
        typeof value === 'string' &&
        value.indexOf(searchString) !== -1
      );
    },
    companyIDFilter (value) {
      value = value.toString().toLocaleUpperCase();
      var searchString = this.companyIDSearch.toString().toLocaleUpperCase();
      if (!searchString) {
        return true;
      }
      return (
        value != null &&
        searchString != null &&
        typeof value === 'string' &&
        value.indexOf(searchString) === 0
      );
    },
    gpoidFilter (value) {
      value = value.toString().toLocaleUpperCase();
      var searchString = this.gpoidSearch.toString().toLocaleUpperCase();

      return (
        value != null &&
        searchString != null &&
        typeof value === 'string' &&
        value.indexOf(searchString) !== -1
      );
    },
    retainedFilter (value) {
      value = value.toString().toLocaleUpperCase();
      var searchString = this.retainedSearch.toString().toLocaleUpperCase();

      return (
        value != null &&
        searchString != null &&
        typeof value === 'string' &&
        value.indexOf(searchString) !== -1
      );
    },
    exportVisible () {
      adminService.getAllVisible().then((res) => {
        var data = res.data.visibleEntities;
        const date = new Date();
        try {
          json2excel({
            data,
            name: 'Visible Entities' + '_' +
              (date.getMonth() + 1) +
              '_' +
              date.getDate() +
              '_' +
              date.getFullYear()
          });
        } catch (e) {
          console.error('Error exporting all visible entities', e);
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
#admin-header {
  font-size: 52px;
  font-weight: 700;
  line-height: 52px;
  letter-spacing: -1.7px;
  text-align: center;
  color: #1e345d;
}
#page-header {
  background: white;
}
.horizontal-divider {
  width: 80px;
  height: 4px;
  margin: auto;
  margin-bottom: 10px;
  /* Orange on Light */
  background: #db7100;
}
.section-header {
  background-color: #1e345d;
  color: white;
}
.dropdown-item {
  font-family: Roboto;

  &.last-or-middle {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
  }
}
.back-btn {
  border-radius: 100%;
}
::v-deep #logs-table {
  .v-data-table-header {
    th {
      background-color: $blue;
      color: white !important;
      font-weight: 700;
      font-size: 15px;
      white-space: nowrap;
      padding: 15px;
      border-left: 1px solid #dddddd !important;
      border-right: 1px solid #dddddd !important;
    }
  }
  td {
    padding-top: 10px;
    padding-bottom: 5px;
    border-left: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
  }
  .v-data-table__mobile-table-row {
    border-top: 5px solid #dddddd;
  }
}
::v-deep #member-settings-table {
  .v-data-table-header {
    th {
      background-color: $blue;
      color: white !important;
      font-weight: 700;
      font-size: 15px;
      white-space: nowrap;
      padding: 15px;
      border-left: 1px solid #dddddd;
      border-right: 1px solid #dddddd;
    }
  }
  td {
    padding-top: 10px;
    padding-bottom: 5px;
    border-left: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
  }
  .v-data-table-header-mobile__wrapper {
    .v-input__slot {
      color: white !important;
    }
    .v-label {
      color: white !important;
    }
  }
  .v-data-table__mobile-table-row {
    border-top: 5px solid #dddddd;
  }
}

::v-deep #edw-table {
  .v-data-table-header {
    th {
      background-color: $blue;
      color: white;
      font-weight: 700;
      font-size: 15px;
      white-space: nowrap;
      padding: 15px;
      border-left: 1px solid #dddddd;
      border-right: 1px solid #dddddd;
    }
  }
  td {
    padding-top: 10px;
    padding-bottom: 5px;
    border-left: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
  }
  .v-data-table__mobile-table-row {
    border-top: 5px solid #dddddd;
  }
}
::v-deep #stb-table {
  .v-data-table-header {
    th {
      background-color: $blue;
      color: white;
      font-weight: 700;
      font-size: 15px;
      white-space: nowrap;
      padding: 15px;
      border-left: 1px solid #dddddd;
      border-right: 1px solid #dddddd;
    }
  }
  td {
    padding-top: 10px;
    padding-bottom: 5px;
    border-left: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
  }
  .v-data-table__mobile-table-row {
    border-top: 5px solid #dddddd;
  }
}
::v-deep #excel-table {
  .v-data-table-header {
    th {
      background-color: $blue;
      color: white;
      font-weight: 700;
      font-size: 15px;
      white-space: nowrap;
      padding: 15px;
      border-left: 1px solid #dddddd;
      border-right: 1px solid #dddddd;
    }
  }
  td {
    padding-top: 10px;
    padding-bottom: 5px;
    border-left: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
  }
  .v-data-table__mobile-table-row {
    border-top: 5px solid #dddddd;
  }
}
.submit-btn {
  background-color: #1e345d !important;
  color: white;
}
.checkbox-container {
  display: inline-block;
}
.member-settings-item {
  pointer-events: auto;
  cursor: pointer !important;
}
::v-deep .v-data-table-header__icon {
  color: white !important;
}
</style>
