<template>
  <div>
    <CancelChangesModal
      v-on="$listeners"
      :change-count="changeCount"
    />
  </div>
</template>

<script>
import CancelChangesModal from './modals/CancelChangesModal.vue';
import { mapActions } from 'vuex';

export default {
  props:
  {
    changeCount: Number
  },
  name: 'UserFeedback',
  components: {
    CancelChangesModal
  },
  methods: {
    dataRefresh () {
      this.$emit('getCompanyInfo');
    },
    ...mapActions({
      postFeedback: 'dashboardItems/postFeedback'
    }),
    feedbackSubmitted (feedback) {
      this.postFeedback(feedback);
      this.$refs.submit.open();
    }
  }
};
</script>

<style>
</style>
