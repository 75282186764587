import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Dashboard from '../views/Dashboard.vue';
import Hierarchy from '../views/Hierarchy.vue';
import Store from '../store/index';
import Landing from '../views/Landing.vue';
import { mixpanelTrack } from '@dependency/mixpanel';
import Admin from '../views/Admin.vue';
import passRoles from '../utilities/roles';
import dashboardService from '../services/dashboard.service';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Landing',
    component: Landing,
    meta: {
      title: 'ExecDash',
      protected: false
    }
  },
  {
    path: '/auth',
    name: 'Auth',
    component: Landing,
    meta: {
      title: 'ExecDash',
      protected: false
    }
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: {
      title: 'ExecDash',
      protected: true
    }
  },
  {
    path: '/Dashboard/:entityType/:entityId/:entityName?',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      title: 'ExecDash',
      protected: true
    }
  },
  {
    path: '/Admin',
    name: 'Admin',
    component: Admin,
    beforeEnter: (to, from, next) => {
      if (from.name !== 'Admin' && from.name !== 'Hierarchy' && from.name !== '/' && from.name !== null) {
        sessionStorage.setItem('beforeAdmin', from.fullPath);
      }
      next();
    },
    meta: {
      title: 'Admin',
      protected: true,
      requiredRole: passRoles.Admin
    }
  },
  {
    path: '/Admin/:entityType/:entityId/:entityName/:company?/:companyId?/:group?/:groupId?/:division?/:divisionId?/:market?/:marketId?',
    name: 'Hierarchy',
    component: Hierarchy,
    meta: {
      title: 'Admin',
      protected: true,
      requiredRole: passRoles.Admin
    }
  },
  {
    path: '*',
    redirect: '/Home'
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(async (to, from, next) => {
  const User = router.app.$userManager;
  if (isMixpanelLogRoute(to.name) && (to.name !== from.name)) {
    const { profile } = await User.getUser();
    var entityId = to.params.entityId;
    var entityType = to.params.entityType;
    dashboardService.logEvent('user viewed: ' + to.path, 'page view', [profile.given_name, profile.family_name].join(' '), profile.sub, entityType, entityId);
    const mixpanelProperties = {
      From: from.meta.title
    };
    for (const property in to.params) {
      mixpanelProperties[property] = to.params[property];
    }
    mixpanelTrack(`/${to.meta.title}`, mixpanelProperties);
  }
  setLandingPageIndicator(to);
  setDashboardPageIndicator(to);
  to.name === 'Auth' ? handleAppUser(User, next) : handleRouteNavigation(User, to, next);
});

router.afterEach((to, from) => {
  Vue.nextTick(() => {
    document.title = to.meta.title || 'Executive-Dashboard';
  });
});

const setLandingPageIndicator = (to) => {
  to.name === 'Auth' || to.name === 'Landing'
    ? Store.commit('setIsLanding', true)
    : Store.commit('setIsLanding', false);
};

const setDashboardPageIndicator = (to) => {
  to.name === 'Dashboard'
    ? Store.commit('setIsDashboard', true)
    : Store.commit('setIsDashboard', false);
};

let redirectAfterSignInUrl;

const setupAppUser = async (User) => {
  await User.signinRedirectCallback().then((user) => {
    redirectAfterSignInUrl = user.state.redirectAfterLogin;
  });

  const { profile } = await User.getUser();
  const {
    given_name: firstName,
    family_name: lastName,
    email,
    sub,
    domain,
    roles
  } = profile;
  const messageOptions = { timeout: 1000, showMessage: true, message: 'Sign In Successful' };

  // const request = { urlKey: 'getPrimaryFacility' };

  const userRoles = JSON.parse(roles);

  Store.commit('setUser', {
    firstName,
    lastName,
    email,
    sub,
    domain,
    roles: userRoles
  });
  Store.commit('setIsSigningIn', false);
  Store.dispatch('messages/setMessage', messageOptions);

  // To-Do : Initialize Mix Panel? - KJW
  // mixpanelInitialize();

  // const userRoleNames = userRoles.map(role => role.RoleName.trim());
};

const hasRequiredRoles = (userRoles) => {
  const requiredRole = Store.getters.getRequiredRole;
  const roleNames = userRoles.map(role => role.RoleName.trim());
  return (roleNames.findIndex(x => x.includes(requiredRole)) > 0);
};

const sendAppUserToLanding = async (message) => {
  const messageOptions = {
    timeout: 2000,
    showMessage: true,
    message: message || 'Please Sign In',
    color: '#971B2E'
  };

  Store.dispatch('messages/setMessage', messageOptions);
  Store.commit('setIsSigningIn', false);
};

const handleAppUser = async (User, next) => {
  try {
    await setupAppUser(User);
    const { roles } = Store.getters.getUser;

    if (!hasRequiredRoles(roles)) {
      // send the app user back to the landing page
      await sendAppUserToLanding('Not Authorized');

      // clear the app user information
      Store.dispatch('clearUser');

      return next('/');
    }

    Store.commit('setIsSigningIn', false);

    if (redirectAfterSignInUrl) {
      const User = router.app.$userManager;
      const { profile } = await User.getUser();
      const routePath = getParameterByName('redirectAfterLogin', redirectAfterSignInUrl);
      const mixpanelProperties = {
        UserID: profile.sub
      };
      if (routePath) {
        mixpanelTrack('Sign in', mixpanelProperties);
        return next(routePath);
      } else {
        mixpanelTrack('Sign in', mixpanelProperties);
        return next('Home');
      }
    } else {
      return next('Home');
    }
  } catch (e) {
    await sendAppUserToLanding();
    return next('/');
  }
};

const getParameterByName = (name, url = window.location.href) => {
  name = name.replace(/[[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
  var results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

const isMixpanelLogRoute = (routeName) => {
  return (routeName !== 'Landing' && routeName !== 'Auth' &&
    routeName !== 'ItemDetailOn' && routeName !== 'ItemDetailOff' &&
    routeName !== 'ContractDetail' && routeName !== 'Home' && routeName !== 'Admin' && routeName !== 'Hierarchy');
};

const handleRouteNavigation = async (User, to, next) => {
  if (!to.meta.protected) {
    return next();
  }

  const signedIn = await User.getUser();

  if (!signedIn && to.name !== 'Landing') {
    sendAppUserToLanding();
    return next(`/?redirectAfterLogin=${to.path}`);
  }

  if (to.matched.some(route => route.meta.requiredRole)) {
    //  check user roles to see if they have requireRole for this route
    const requiredRole = to.matched.find(route => route.meta.requiredRole).meta.requiredRole;
    const userRoles = Store.getters.getUser.roles.map(role => role.RoleName.trim());
    const userHasRequiredRole = userRoles.includes(requiredRole);
    if (!userHasRequiredRole) {
      await sendAppUserToLanding('Not Authorized');
      Store.dispatch('clearUser');
      return next('/');
    }
  }

  return next();
};

export default router;
