import { Log, UserManager } from 'oidc-client-ts';
import authSettings from './authSettings';
import runtimeConfig from '../../runtimeConfig';

const env = runtimeConfig.ENV;

const initializeUserService = () => {
  const um = {
    userManager: new UserManager(authSettings)
  };

  if (env === 'local' || env === 'development') {
    console.log('UserManager: ', um);
    um.userManager.events.addUserLoaded(function () {
      console.log('User loaded event fired.');
    });
    um.userManager.events.addAccessTokenExpiring(() => {
      console.log('Access token is expiring.');
    });
    um.userManager.events.addAccessTokenExpired(() => {
      console.log('Access token is now expired.');
    });
    um.userManager.events.addUserSignedOut(() => {
      console.log('User signed out.');
    });
    um.userManager.events.addUserSignedIn(() => {
      console.log('User signed in.');
    });
    Log.setLevel(Log.WARN);
    Log.setLogger(console);
  }

  return um;
};

export default initializeUserService;
