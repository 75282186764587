import ServiceFactory from '../../services/service-factory';

const getDefaultState = () => {
  return {
    activeApiCalls: 0,
    showIntroModal: true,
    organizationData: {},
    companyInfo: {},
    releaseVersion: 1.0,
    firstVisit: true
  };
};

const state = getDefaultState();

const getters = {
  getOrganizationData (state) {
    return state.organizationData;
  },
  getReleaseVersion (state) {
    return state.releaseVersion;
  },
  getProgressBarStatus (state) {
    return state.activeApiCalls > 0;
  },
  getShowIntroModal (state) {
    return state.showIntroModal;
  },
  getFirstVisit (state) {
    return state.firstVisit;
  }
};

const mutations = {
  setOrganizationData (state, payload) {
    state.organizationData = payload;
  },
  setProgressBarStatus (state, status) {
    if (status) {
      state.activeApiCalls++;
    } else {
      state.activeApiCalls--;

      // Failsafe
      if (state.activeApiCalls < 0) {
        state.activeApiCalls = 0;
      }
    }
  },
  resetActiveApiCalls (state) {
    state.activeApiCalls = 0;
  },
  setShowIntroModal (state, status) {
    state.showIntroModal = status;
  },
  setFirstVisit (state, status) {
    state.firstVisit = status;
  }
};

const actions = {
  async fetchOrganizationData ({ commit }) {
    const { data } = await new ServiceFactory().get('dashboard').getFilterData();
    commit('setOrganizationData', data);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
