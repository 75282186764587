<template>
  <article>
    <v-card>
      <v-card-title class="justify-center text-center" style="word-break: break-word">
        Admin Fees and Rebates &nbsp;
        <v-tooltip v-model="show" max-width="400" top>
          <template v-slot:activator="{on, attrs}">
            <v-icon color="#1E345D" @click="show = !show" v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
          </template>
          <span>Year over year admin fees and rebates. Current year displays year to date admin fees and rebates. Source: RAFT Allocated Fees & Retained Spend</span>
        </v-tooltip>
      </v-card-title>
      <v-card-subtitle class="justify-center text-center caption font-italic">
        <i>Values shown below are based on calendar year</i>
      </v-card-subtitle>
      <Chart Chart v-if="this.chartOptions != null" :options="chartOptions"></Chart>
      <span class="text-center grey--text" v-if="this.chartOptions === null">Awaiting Data Feed from Member</span>
    </v-card>
  </article>
</template>

<script>
import { Chart } from 'highcharts-vue';
import dashboardService from '../services/dashboard.service';

export default {
  components: { Chart },
  data () {
    return {
      show: false,

      chartOptions: {
        plotOptions: {
          column: {
            stacking: 'normal',
            dataLabels: {
              enabled: false
            }
          }
        },
        chart: {
          type: 'column',
          style: {
            fontFamily: 'Roboto'
          }
        },
        title: {
          text: ''
        },
        credits: {
          enabled: false
        },
        tooltip: {
          formatter: function () {
            const currencyFormatter = new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
              maximumFractionDigits: 0
            });
            return this.series.name + ': ' + currencyFormatter.format(this.y);
          }
        },
        xAxis: {
          categories: []
        },
        yAxis: {
          min: 0,
          title: {
            text: ''
          },
          stackLabels: {
            enabled: true,
            formatter: function () {
              const currencyFormatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                maximumFractionDigits: 0
              });
              return currencyFormatter.format(this.total);
            }
          },
          labels: {
            // eslint-disable-next-line no-template-curly-in-string
            format: '${text}'
          }
        },
        series: [
          {
            name: 'Admin Fees',
            data: [],
            color: '#1E345D',
            legendIndex: 0,
            index: 3
          },
          {
            name: 'Rebates',
            data: [],
            color: '#99caea',
            legendIndex: 1,
            index: 2
          }
        ]
      }
    };
  },
  async created () {
    this.feesAndRebatesChartSetup();
  },
  methods: {
    feesAndRebatesChartSetup () {
      dashboardService.getAdminFees(this.$route.params.entityType, this.$route.params.entityId, 5).then((res) => {
        const feesAndRebates = res.data.adminFees;
        const currentYear = new Date().getFullYear();
        const years = [];
        const adminFees = [];
        const rebates = [];
        var total = 0;

        if (feesAndRebates && feesAndRebates.length) {
          res.data.adminFees.forEach(year => {
            total += (year.rebate + year.adminFee);
          });
          if (total > 0) {
            while (feesAndRebates.length < 5) {
              if (feesAndRebates[feesAndRebates.length - 1].year !== currentYear) {
                feesAndRebates.push({ year: (feesAndRebates[feesAndRebates.length - 1].year + 1), adminFee: 0, rebate: 0 });
              } else if (feesAndRebates[feesAndRebates.length - 1].year === currentYear) {
                for (let i = 0; i < 4; i++) {
                  if (feesAndRebates[i].year !== (currentYear - (4 - i))) {
                    feesAndRebates.splice(i, 0, { year: (currentYear - (4 - i)), adminFee: 0, rebate: 0 });
                  }
                }
              }
            };

            res.data.adminFees.forEach((item) => {
              years.push(item.year);
              adminFees.push(item.adminFee);
              rebates.push(item.rebate);
            });
            this.chartOptions.xAxis.categories = years;
            this.chartOptions.series[0].data = adminFees;
            this.chartOptions.series[1].data = rebates;
          } else {
            this.chartOptions = null;
          }
        } else {
          this.chartOptions = null;
        }
      });
    }
  }
};
</script>
