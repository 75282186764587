import apiBase from './api-base';

const resource = '/Dashboard';
export default {
  getCompanies () {
    return apiBase.get(`${resource}/organizational-information`);
  },
  groups (entityId) {
    return apiBase.get(`${resource}/group/${entityId}`);
  },
  divisions (entityId) {
    return apiBase.get(`${resource}/division/${entityId}`);
  },
  markets (entityId) {
    return apiBase.get(`${resource}/market/${entityId}`);
  },
  facilities (entityId) {
    return apiBase.get(`${resource}/facility/${entityId}`);
  },
  getLookupValues () {
    return apiBase.get(`${resource}/lookup-values`);
  },
  getOrganizationalInformationById (type, id) {
    return apiBase.get(`${resource}/organizational-information/${type}/${id}`);
  },
  getSpendGrowth (type, id, years) {
    return apiBase.get(`${resource}/spendgrowth/${type}/${id}/${years}`);
  },
  getAdminFees (type, id, years) {
    return apiBase.get(`${resource}/adminfees/${type}/${id}/${years}`);
  },
  getKPI (type, id, years) {
    return apiBase.get(`${resource}/kpi/${type}/${id}/${years}`);
  },
  getContractPerformance (type, entityId) {
    return apiBase.get(`${resource}/contractperformance/${type}/${entityId}`);
  },
  getAwardStatus (type, id) {
    return apiBase.get(`${resource}/contractperformanceawardstatus/${type}/${id}`);
  },
  getCPBR (id) {
    return apiBase.get(`${resource}/contractperformancebenchmark/${id}`);
  },
  getStrategicSolutions (id) {
    return apiBase.get(`${resource}/strategicsolutions/${id}`);
  },
  getFootprint (entityType, entityId, designationId) {
    return apiBase.get(`${resource}/footprint/${entityType}/${entityId}/${designationId}`);
  },
  stageToBase () {
    return apiBase.get(`${resource}/stage-to-base`);
  },
  logEvent (message, messageType, user, userID, entityType, entityId) {
    const jsonMessage = JSON.stringify(message);
    const customConfig = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    return apiBase.post(`${resource}/logging/${messageType}/${user}/${userID}/${entityType}/${entityId}`, jsonMessage, customConfig);
  },
  getLogReport (messageType, dateFrom, dateTo, searchName) {
    return apiBase.get(`${resource}/logging/loggingreport/${messageType}/${dateFrom}/${dateTo}/${searchName}`);
  }
};
