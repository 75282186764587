<template>
  <v-card max-height="325">
    <v-card-title class="justify-center font-weight-bold">
      Important Account Dynamics
    </v-card-title>
    <v-card-text>
      <ul>
        <li class="mb-3" v-for="(item, i) in displayValues" :key="i">
          {{ item.title }}
        </li>
      </ul>
    </v-card-text>
    <v-divider class="mx-3"></v-divider>
  </v-card>
</template>

<script>
export default {
  props: { displayValues: Array }
};
</script>

<style>
.label{
  font-weight: bold;
}
ul {
   list-style: none;
   margin-left: 0;
   padding-left: 1em;
   text-indent: -1em
}
.v-card {
  display: flex !important;
  flex-direction: column;
}

.v-card__text {
  flex-grow: 1;
  overflow: auto;
}
</style>
