import * as LDClient from 'launchdarkly-js-client-sdk';
import runtimeConfig from '../../runtimeConfig';

const launchDarklyKey = runtimeConfig.LAUNCH_DARKLY_KEY;
let ldClient = null;

if (launchDarklyKey) {
  ldClient = LDClient.initialize(launchDarklyKey, {
    anonymous: true
  }, {
    bootstrap: 'localStorage'
  });
}

export default {
  install: (Vue, options) => {
    // inject a globally available property
    Vue.prototype.$featureFlagsManager = ldClient;
  }
};
