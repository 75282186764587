<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <template v-slot:activator="{ on, attrs}">
        <div v-bind="attrs" v-on="on" class="modal-div">
          <v-btn :disabled="changeCount === 0" dark color="error">
            Cancel Changes
            <v-icon class="ml-2"> mdi-cancel </v-icon>
          </v-btn>
        </div>
      </template>

      <v-card class="justify-center">
        <v-card-title class="text-h5 grey lighten-2 justify-center">
          CANCEL ALL CHANGES?
        </v-card-title>

        <v-divider></v-divider>

        <v-card-actions class="justify-center">
          <v-btn
            color="primary"
            @click="dialog = false"
          >
            EXIT
          </v-btn>
          <v-btn
            color="error"
            @click="
            cancelChanges()
            dialog = false"
          >
            CONFIRM CANCEL
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props:
  {
    changeCount: Number
  },
  data () {
    return {
      dialog: false
    };
  },
  methods: {
    cancelChanges () {
      this.$emit('cancel-changes');
    }
  }
};
</script>
<style>
.modal-div{
  width: 10%;
}
</style>
