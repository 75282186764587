import store from '../../store/index';
import mixpanel from 'mixpanel-browser';
import runtimeConfig from '../../runtimeConfig';

const mixpanelToken = runtimeConfig.MA_EXECUTIVE_DASH_MIXPANEL_TOKEN;
const env = runtimeConfig.ENV;

let initialized = false;

export const mixpanelInitialize = () => {
  if (!initialized) {
    const user = store.getters['getUser'];
    const orgInfo = store.getters['getUserOrganization'];
    mixpanel.init(mixpanelToken);
    mixpanel.identify(`${user.firstName}-${user.lastName}-${user.sub}`);
    mixpanel.people.set({
      $distinct_id: `${user.firstName}-${user.lastName}-${user.sub}`,
      COID: orgInfo.coid,
      $name: `${user.firstName} ${user.lastName}`,
      'Member Affiliation': user.domain,
      $email: user.email
    });
  }

  initialized = true;
};

export const mixpanelTrack = (eventName, props = {}) => {
  if (initialized) {
    Object.keys(props).length ? mixpanel.track(eventName, props) : mixpanel.track(eventName);
  } else {
    try {
      mixpanelInitialize();
      mixpanelTrack(eventName, props);
    } catch (error) {
      if (env === 'development' || env === 'local') {
        console.error(error);
      }
    }
  }
};
