<template>
  <v-app>
    <v-snackbar
      v-model="isShowingMessage"
      :class="customClass"
      :color="color"
      :content-class="textColor"
      :timeout="timeout"
      top
    >
      {{ appMessage }}

      <template v-slot:action="{ attrs }">
        <v-icon v-on="attrs" @click="setShowMessage(false)" class="state-icon">
          mdi-close
        </v-icon>
      </template>
    </v-snackbar>

    <v-app-bar hide-on-scroll color="#1E345D" app>
      <router-link v-if="!isLanding" to="/home"
        ><img
          class="mr-3 hidden-xs-only"
          :src="require('./assets/HPG_logo_hor_white_rev.png')"
          height="40"
      /></router-link>
      <router-link v-if="isLanding" to="/"
        ><img
          class="mr-3 hidden-xs-only"
          :src="require('./assets/HPG_logo_hor_white_rev.png')"
          height="40"
      /></router-link>

      <v-menu v-if="!isLanding && isDashboard" offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-app-bar-nav-icon color="white" />
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            href="#top"
            class="dropdown-item profile logout htBlue--text"
          >
            <v-list-item-title>Top of Page</v-list-item-title>
          </v-list-item>
          <v-list-item
            href="#spend_growth"
            class="dropdown-item profile htBlue--text"
          >
            <v-list-item-title>Spend Through GPO Trend</v-list-item-title>
          </v-list-item>
          <v-list-item
            href="#kpi_table"
            class="dropdown-item profile htBlue--text"
          >
            <v-list-item-title>KPI Overview</v-list-item-title>
          </v-list-item>
          <v-list-item
            href="#contract_performance_header"
            class="dropdown-item profile htBlue--text"
          >
            <v-list-item-title>Contract Performance</v-list-item-title>
          </v-list-item>
          <v-list-item
            href="#footprint_table"
            class="dropdown-item profile htBlue--text"
          >
            <v-list-item-title>HealthTrust Footprint</v-list-item-title>
          </v-list-item>
          <v-list-item
            href="#services_resources_header"
            class="dropdown-item profile htBlue--text"
          >
            <v-list-item-title>Services & Resources</v-list-item-title>
          </v-list-item>
          <v-list-item
            href="#contact_table"
            class="dropdown-item profile htBlue--text"
          >
            <v-list-item-title>Executive Contacts</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-spacer class="hidden-md-and-up"></v-spacer>
      <router-link to="/home"
        ><img
          class="ml-3 hidden-sm-and-up"
          :src="require('./assets/HealthTrust_Logo_Sm.png')"
          height="40"
      /></router-link>
      <v-spacer class="hidden-sm-and-up"></v-spacer>

      <v-spacer class="hidden-sm-and-down"></v-spacer>

      <div v-if="!isLanding" class="mx-5 hidden-sm-and-down">
        <a
          href="mailto:hpg.customersolutions@healthtrustpg.com?subject=Exec Dash Help Needed"
        >
          <v-btn>
            <v-icon color="black" class="mr-2">
              mdi-message-text-outline
            </v-icon>
            Need Help?
          </v-btn>
        </a>
      </div>

      <v-menu v-if="!isLanding" offset-y class="hidden-md-and-up">
        <template v-slot:activator="{ on, attrs }">
          <div class="d-flex label-icon" v-bind="attrs" v-on="on">
            <v-avatar
              :color="isCopied ? 'green' : 'white'"
              size="36"
              class="profile"
              @dblclick="xid"
            >
              {{ getInitials() }}
            </v-avatar>
            <v-icon color="white">mdi-menu-down </v-icon>
          </div>
        </template>
        <v-list>
          <v-list-item class="dropdown-item profile">
            <v-list-item-title class="d-flex py-5">
              <v-icon x-large class="mr-3"> mdi-account-circle</v-icon>
              <div>
                <p class="user-name mb-0">
                  {{ user.lastName }}, {{ user.firstName }}
                </p>
                <p class="user-email mb-0">
                  {{ user.email }}
                </p>
              </div>
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            link
            @click="openUrlInNewTab('PASS')"
            class="dropdown-item"
          >
            <v-list-item-title class="d-flex">
              <v-icon color="#DB7100" class="dropdown-icon mx-2">
                mdi-account-cog-outline
              </v-icon>
              PASS
            </v-list-item-title>
          </v-list-item>
          <a
            class="black--text"
            href="mailto:hpg.customersolutions@healthtrustpg.com?subject=Exec Dash Help Needed"
          >
            <v-list-item class="hidden-md-and-up dropdown-item logout">
              <v-list-item-title class="d-flex">
                <v-icon color="#DB7100" class="dropdown-icon mx-2">
                  mdi-message-text-outline
                </v-icon>
                Need Help?
              </v-list-item-title>
            </v-list-item>
          </a>
          <v-list-item
            v-show="hasRoleForRoute('Admin')"
            class="dropdown-item logout"
            link
            @click="goTo('Admin')"
          >
            <v-list-item-title class="d-flex">
              <v-icon color="#DB7100" class="dropdown-icon mx-2">
                mdi-security
              </v-icon>
              Admin
            </v-list-item-title>
          </v-list-item>
          <v-list-item link @click="signInAction" class="dropdown-item logout">
            <v-list-item-title class="logout">
              <v-icon color="#212121" class="dropdown-icon mx-2"
                >mdi-logout</v-icon
              >
              Sign Out
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-main class="main-content">
      <router-view />
    </v-main>
    <v-footer padless class="app-footer">
      <section class="bottom-footer" flat tile width="100%">
        <section class="application-info">
          <section class="ht-info">
            <section class="links">
              <span class="copyright">
                <v-icon class="icon" small> mdi-copyright</v-icon>
                2023 HealthTrust
              </span>

              <span class="pipe">|</span>

              <span class="link">
                <v-dialog
                  width="960"
                  v-model="showTermsOfUse"
                  scrollable
                  id="TermsOfUseDialog"
                >
                  <template
                    v-slot:activator="{ on, attrs }"
                    id="TermsOfUseButtonContainer"
                  >
                    <span v-bind="attrs" v-on="on" id="TermsOfUseLink"
                      >Terms of Use</span
                    >
                  </template>
                  <v-card class="terms-modal" id="TermsModal">
                    <figure class="modal-header" id="TermsModalHeader">
                      <div class="header-left">
                        <span class="title-text" id="TermsTitleText">
                          Terms
                        </span>
                      </div>
                      <div class="header-right">
                        <span id="ModalCloseContainer">
                          <v-icon
                            @click="closeTermsOfUse"
                            class="terms-close-icon"
                            medium
                            id="ModalCloseIcon"
                          >
                            mdi-close
                          </v-icon>
                        </span>
                      </div>
                    </figure>

                    <section class="modal-content-section">
                      <div class="modal-content">
                        <span class="heading disclaimer">
                          HealthTrust Disclaimer
                        </span>
                        <span class="text-body">
                          HealthTrust contracted terms and pricing are
                          proprietary and strictly confidential information.
                          Misuse of this information may constitute a violation
                          of your Participation Agreement. Terms and pricing
                          information must not be shared with any third parties
                          without HealthTrust’s prior written consent.
                        </span>

                        <span class="heading terms">
                          HealthTrust Terms of Use
                        </span>
                        <span class="text-body">
                          HealthTrust Members enjoy access to superior value and
                          best-in-class pricing from vendors across a broad
                          portfolio of products and services. As such, Members
                          are obligated by the Confidentiality provisions of
                          their Participation Agreements to maintain information
                          regarding pricing, rebates, discounts, shipping, and
                          other terms and conditions of HealthTrust's vendor
                          contracts in strict confidence. Unauthorized
                          disclosures of this confidential information to third
                          parties affects HealthTrust's ability to secure and
                          maintain favorable pricing and terms for our
                          membership. If you are or have been asked by any
                          unauthorized third party to disclose confidential
                          information about the pricing or terms of a
                          HealthTrust vendor agreement, please be reminded of
                          your obligation not to disclose such information.
                          Thank you for your continuing diligence and attention
                          to this important matter.
                        </span>

                        <span class="heading terms">
                          Analytics Terms of Use
                        </span>
                        <span class="text-body">
                          This analysis is provided as-is with no warranty of
                          any kind. As a licensed user of HealthTrust's services
                          you are permitted to use this document for internal
                          purposes only. Any distribution outside of your
                          organization (or other use of this analysis) is
                          strictly prohibited.
                        </span>
                      </div>
                    </section>

                    <section class="modal-footer">
                      <span class="footer">
                        <v-btn @click="closeTermsOfUse">Okay</v-btn>
                      </span>
                    </section>
                  </v-card>
                </v-dialog>
              </span>
              <span class="pipe">|</span>

              <span @click="openUrlInNewTab('Privacy Policy')" class="link"
                >Privacy Policy</span
              >
            </section>
            <section class="app-version">
              <span class="version-info">Version: </span>
              <span class="version">2.1</span>
            </section>
          </section>
        </section>
      </section>
    </v-footer>
  </v-app>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import runtimeConfig from './runtimeConfig';

const env = runtimeConfig.ENV;

export default {
  name: 'App',
  data () {
    return {
      showTermsOfUse: false,
      showSignout: false,
      isCopied: false,
      version: process.env.VUE_APP_VERSION
    };
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
      isSigningIn: 'getIsSigningIn',
      showMessage: 'messages/getShowMessage',
      appMessage: 'messages/getAppMessage',
      timeout: 'messages/getTimeout',
      customClass: 'messages/getCustomClass',
      color: 'messages/getColor',
      textColor: 'messages/getTextColor',
      isLanding: 'getIsLanding',
      isDashboard: 'getIsDashboard',
      getFeatureFlag: 'getFeatureFlag'
    }),
    distributorProfileBlocked () {
      return this.getFeatureFlag('me-distributor-profile');
    },
    hasUser () {
      return Object.keys(this.user)?.length;
    },
    menuIcon () {
      return this.showSignout ? 'mdi-menu-up' : 'mdi-menu-down';
    },
    isShowingMessage: {
      get () {
        return this.showMessage;
      },
      set (value) {
        this.setShowMessage(value);
      }
    },
    flags () {
      return {
        contractRestrictions: this.$store.getters.getFeatureFlag(
          'me-contract-restrictions'
        )
      };
    }
  },
  created () {
    this.fetchFeatureFlags();
  },
  methods: {
    ...mapMutations({
      setIsSigningIn: 'setIsSigningIn',
      setUser: 'setUser',
      setShowMessage: 'messages/setShowMessage'
    }),
    ...mapActions({
      setMessage: 'messages/setMessage',
      signOutAction: 'signOut',
      fetchFeatureFlags: 'fetchFeatureFlags'
    }),
    goTo (route) {
      this.$router.push(`/${route}`);
    },
    goToDistributorProfile () {
      this.$router.push('/distributorprofile');
    },
    openUrlInNewTab (routeName) {
      switch (routeName) {
        case 'PASS':
          window.open(runtimeConfig.PASS_URL, '_blank');
          break;
        case 'Privacy Policy':
          window.open(
            'https://healthtrustpg.com/privacy-policy/',
            'rel=noopener',
            '_blank'
          );
          break;
      }
    },
    async signOut () {
      const messageOptions = {
        showMessage: true,
        timeout: 1000,
        message: 'Sign Out Successful. See you soon!'
      };
      await this.signOutAction();
      this.setUser({});
      this.$router.push('/');
      this.setMessage(messageOptions);
    },
    async signInAction () {
      (await this.$userManager.getUser()) ? this.signOut() : this.signIn();
    },
    messageHandle () {
      this.setShowMessage(this.hasMessage);
    },
    setShowSignout () {
      this.showSignout = !this.showSignout;
    },
    async xid () {
      if (env === 'development' || env === 'local') {
        const { access_token } = await this.$userManager.getUser();
        navigator.clipboard.writeText(`Bearer ${access_token}`);
        this.isCopied = true;
        setTimeout(() => (this.isCopied = false), 1000);
      }
    },
    hasRoleForRoute (routeName) {
      const userRoles = this.user.roles.map((role) => role.RoleName);
      const requiredRole = this.$router.options.routes.find(
        (route) => route.name === routeName
      ).meta?.requiredRole;
      if (userRoles.includes(requiredRole)) {
        return true;
      }
      return false;
    },
    closeTermsOfUse () {
      this.showTermsOfUse = false;
    },
    getInitials () {
      return `${this.user.firstName[0]}${this.user.lastName[0]}`;
    }
  }
};
</script>
<style lang="scss">
.app-header {
  background-color: $blue;
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 75em) {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin: auto;
    height: 4em;
  }

  .header-image-container {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    align-self: center;
    max-width: 20em;
    padding: 1em;

    .header-image {
      width: 80%;
    }
  }

  .main-navigation {
    display: flex;
    justify-content: center;

    .navigation-list {
      display: flex;
      flex-direction: row;
      justify-content: center;

      .list-link {
        align-items: center;
        display: flex;
        padding: 1em;

        a {
          color: #fff;

          &.router-link-active {
            font-weight: bold;
            font-size: 1.1em;
          }
        }
      }
    }
  }

  .header-greeting {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    min-width: 10em;
    padding: 1em;

    @media only screen and (min-width: 75em) {
      justify-content: flex-end;
      width: 100%;
    }

    .greeting-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      @media only screen and (min-width: 75em) {
        align-items: flex-end;
      }

      .content-button {
        margin-bottom: 1em;
      }

      .content-label {
        display: flex;
      }

      .label-text-container {
        display: flex;

        .feedback {
          padding-top: 0.4em;
        }

        .profile {
          color: black;
          font-weight: 700;
        }

        .label-text {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-bottom: 0;

          .text-name {
            display: flex;
            padding-left: 0.5em;
          }

          .text-icon {
            .label-icon {
              margin-right: 0.2em;
              margin-left: 0.2em;
              color: white;

              &.theme--light.v-icon:focus::after {
                background-color: transparent;
              }
            }
          }
        }
      }

      .label-button {
        align-self: flex-end;
        padding-top: 0.5em;

        .button {
          text-transform: capitalize;
        }
      }
    }
  }
}
.dropdown-item {
  font-family: Roboto;

  &.profile {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);

    .user-name {
      font-weight: 700;
      font-size: 15px;
      line-height: 21px;
      letter-spacing: 0.15px;
      color: #1e345d;
    }

    .user-email {
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      letter-spacing: -0.1px;
      color: rgba(0, 0, 0, 0.54);
    }
  }

  &.logout {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
  }

  .edit-markup {
    margin-top: 1em;
  }
}

.logout {
  font-family: Roboto;
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  letter-spacing: -0.1px;
  color: rgba(0, 0, 0, 0.87);
}

.header-sub-navigation {
  border-bottom: 2px solid gainsboro;
  background-color: #0c1f44;

  .sub-navigation-list {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 90em;

    .sub-list-link {
      padding: 0.5em 0.5em 0 0.5em;
      width: 10em;

      a {
        color: rgba(255, 255, 255, 0.7);
        display: flex;
        justify-content: center;
        width: 100%;
        padding-bottom: 0.5em;

        &.router-link-active {
          font-weight: 700;
          color: white;
          border-bottom: 2px solid #db7100;
        }
      }
    }
  }
}

.app-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $light-grey--background;
  padding-top: 1em;
  font-family: 'Roboto';
  white-space: nowrap !important;

  @media only screen and (min-width: 75em) {
    justify-content: space-between;

    .resource-info {
      .link-text {
        &.copy {
          text-align: left;
        }
      }
    }
  }
  .bottom-footer {
    width: 100%;
    display: flex;
    flex-direction: row;

    .application-info {
      width: 100%;
      display: flex;
      flex-direction: row;
      background-color: white;
      padding-left: 1em;
      height: 2em;
      align-items: center;
      font-size: 0.8125em;
      color: $blue;

      .ht-info {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-top: 1em;

        @media only screen and (min-width: 60em) {
          flex-direction: row;
          padding-top: 0;
        }

        .links {
          width: 100%;

          .copyright {
            font-weight: 700;
            color: $blue;

            .icon {
              color: $blue;
            }
          }

          .pipe {
            color: $blue;
            padding: 0 1em;
          }

          .link {
            color: $blue;
            text-decoration: underline;
            cursor: pointer;
          }
        }

        .app-version {
          width: 50%;
          padding-right: 2em;

          @media only screen and (min-width: 60em) {
            width: 100% !important;
            text-align: right !important;
          }

          .version-info {
            width: 100%;

            .version {
              font-weight: 700;
            }
          }
        }
      }
    }
  }
}

.v-dialog {
  font-family: 'Roboto';
  border-radius: 10px;
  box-shadow: 0px 12px 17px rgba(0, 0, 0, 0.14),
    0px 5px 22px rgba(0, 0, 0, 0.12), 0px 7px 8px rgba(0, 0, 0, 0.2);

  .terms-modal {
    background-color: $grey--background--modal;
    height: fit-content;

    .modal-header {
      display: flex;
      flex-direction: row;
      background: $blue;
      padding: 1.5em 2em;

      .header-left {
        width: 100%;

        .title-text {
          font-weight: 700;
          font-size: 1.3125em;
          color: white;
        }
      }

      .header-right {
        width: 100%;
        text-align: right;

        .mdi-close::before {
          color: $light-grey;
        }
      }
    }

    .modal-content-section {
      display: flex;
      flex-direction: column;
      background-color: $white;
      margin: 2em;
      box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14),
        0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
      border-radius: 5px;

      .modal-content {
        display: flex;
        flex-direction: column;
        background-color: white;
        margin: 2em;

        .heading {
          width: 100%;
          font-size: 1.1875em;
          padding-bottom: 0.5em;

          &.terms {
            font-weight: bold;
            font-size: 0.9375em;
          }
        }

        .text-body {
          width: 100%;
          font-size: 0.9375em;
          padding-bottom: 2em;
        }
      }
    }

    .modal-footer {
      background-color: white;
      box-shadow: 0px -2px 3px rgba(0, 0, 0, 0.12);
      padding: 1em 2em;

      .v-btn {
        background-color: $orange--background-button;
      }
    }
  }
}

.custom-color {
  .v-sheet.v-snack__wrapper:not(.v-sheet--outlined) {
    background-color: gainsboro !important;
    color: red;
    .v-icon {
      color: red;
    }
  }
}

.warning-color {
  .v-sheet.v-snack__wrapper:not(.v-sheet--outlined) {
    background-color: #faebe9 !important;
    color: #971b2f;
    .v-icon {
      color: #971b2f;
    }
  }
}

.success-color {
  .v-sheet.v-snack__wrapper:not(.v-sheet--outlined) {
    background-color: #4a7e23 !important;
    color: white;
    .v-icon {
      color: white;
    }
  }
}

.main-content {
  background-color: #f5f5f5;
}
</style>

<style lang="scss">
@font-face {
  font-family: 'MarkforHCA';
  src: url('./assets/webfonts/MarkforHCA-Bold.woff');
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'MarkforHCA';
  src: url('./assets/webfonts/MarkforHCA-Medium.woff');
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: 'MarkforHCA';
  src: url('./assets/webfonts/MarkforHCA-Regular.woff');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'MarkforHCA';
  src: url('./assets/webfonts/MarkforHCA-Book.woff');
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-Regular.ttf');
}

h1 {
  font-size: 2.25rem !important;
  font-weight: 500 !important;
}

h2 {
  font-size: 2rem !important;
  font-weight: 700 !important;
}

h3 {
  font-size: 1.75rem !important;
  font-weight: 700 !important;
}

h4 {
  font-size: 1.5rem !important;
  font-weight: 500 !important;
}

h5 {
  font-size: 1.25rem !important;
  font-weight: 300 !important;
}

h6 {
  font-size: 1.125rem !important;
  font-weight: 500 !important;
}

.heading-6 {
  font-size: 1.125rem !important;
  font-weight: 500 !important;
}

.body {
  font-size: 1rem !important;
  font-weight: 400 !important;
}

#ma-se-app {
  font-family: 'MarkforHCA', Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: black;
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
}

.container {
  padding: 0px;
  position: inherit;
  height: 100% !important;
}

.bi-chevron-right {
  color: gray;
}

.member-portal {
  text-align: right;
}

.tooltip-inner {
  text-align: left !important;
}

#app-icon {
  color: #0074b8;
}

.feedback {
  white-space: nowrap;
}

#dropdown-menu {
  border: none;
  background-color: transparent;
  color: #000000;
  font-size: 16px;
  margin: 0 1%;
  padding: 0;
}

.modal {
  color: black;
}

.modal-dialog {
  /* max width is only here to overwrite bootstrap max width */
  max-width: 80% !important;
  width: 40% !important;
  min-width: 800px !important;
  border-radius: 10px;
}

.modal-content {
  height: 40% !important;
}

.modal-header {
  border-bottom: none !important;
}

.modal-footer {
  border-top: none !important;
}
</style>

<style scoped lang="scss">
::v-deep .v-btn {
  border-radius: 4px;
  padding: 8px 16px;
}

::v-deep .v-pagination__item,
::v-deep .v-pagination__navigation {
  box-shadow: none !important;
  border: 1px solid #d9d9d6;
}

::v-deep .v-chip:hover::before {
  opacity: 0 !important;
}
</style>

<style scoped>
::v-deep .v-btn.secondary {
  color: #0074b8 !important;
  border: 1px solid #0000001f !important;
}

::v-deep .accentWhite {
  color: #f38b00 !important;
  border: 1px solid #f38b00 !important;
}

::v-deep .v-btn--is-elevated {
  box-shadow: none;
}
::v-deep tbody tr :hover {
  cursor: default;
}
</style>
