<template>
  <article>
    <v-card
      id="contract_performance"
    >
      <v-card-title
        id="contract_performance_header"
        class="justify-center"
      >
        Contract Performance
      </v-card-title>
      <v-card-subtitle
        class="text-center caption font-italic"
      >
      <i>
        Values shown below are based on the trailing 12 months (TTM {{ this.ttmStart }} - {{ this.ttmEnd }})
      </i>
    </v-card-subtitle>
      <v-divider class="pb-3"/>
      <v-container>
        <v-row v-if="this.medsurg.series[0].data[0].y != null || this.cumulative.series[0].data[0].y || this.rx.series[0].data[0].y">
          <v-col cols="12" md="4">
            <v-card flat class="mx-auto">
              <v-card-title class="contract-performance-title" >
                <span>
                  MedSurg - Facility Invoice Feed
                  <v-tooltip
                  top max-width="400"
                  open-on-click
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="#1E345D"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-information-outline
                    </v-icon>
                  </template>
                  <span>
                    Invoice data from the Member's data feed for TTM. <br/>

                    On contract Medsurg spend / contract eligible spend at the time of purchase <br/>

                    Source: Member's Data Feed
                  </span>
                </v-tooltip>
                </span>
              </v-card-title>
              <v-card-text>
                <Chart v-if="this.medsurg.series[0].data[0].y" :options="medsurg"/>
                <h5
                  v-if="this.medsurg.series[0].data[0].y == 0"
                  class="text-center mb-2"
                >
                Awaiting Data Feed from Member
                </h5>
                <v-expansion-panels v-if="this.medsurg.series[0].data[0].y !== 0" id="award-status-panels">
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      MedSurg Normalized Award Status
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-simple-table id="award-status-table" dense>
                        <thead>
                          <tr>
                            <td>Contract Type</td>
                            <td>On</td>
                            <td class="off-contract">Off</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="item in awardStatus" :key="item.index">
                            <td>{{ item.awardStatus }}</td>
                            <td>{{ (item.contractPerformancePercentOnContract  * 100).toFixed(2) }}%</td>
                            <td>{{ (item.contractPerformancePercentOffContract * 100).toFixed(2) }}%</td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="4">
            <v-card flat class="mx-auto">
              <v-card-title class="contract-performance-title">
                <span>
                  Cumulative Contract Performance
                  <v-tooltip top max-width="400" open-on-click>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="#1E345D" v-bind="attrs" v-on="on"
                        >mdi-information-outline</v-icon
                      >
                    </template>
                    <span>
                      Invoice data from the Member's data feed for TTM. <br/>

                      On contract RX + Medsurg contact / contract eligble RX + Medsurg spend at time of purchase <br/>

                      Source: Member's Data Feed and Pharmacy Wholesaler Data
                    </span>
                  </v-tooltip>
                </span>
              </v-card-title>
            <v-card-text>
              <Chart v-if="this.cumulative.series[0].data[0].y" :options="cumulative"/>
            <h5 class="text-center mb-2" v-if="this.cumulative.series[0].data[0].y == 0">
               Awaiting Data Feed from Member
            </h5>
            </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="4">
            <v-card flat>
              <v-card-title class="contract-performance-title-right">
                <span>
                  Rx - Wholesaler Feed
                  <v-tooltip top max-width="400" open-on-click>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="#1E345D" v-bind="attrs" v-on="on"
                        >mdi-information-outline</v-icon
                      >
                    </template>
                    <span>
                      Invoice data from the Supplier data feed for TTM. <br/>

                      On contract Rx-Wholesaler / contract eligible spend at the time of purchase <br/>

                      Source: Pharmacy Wholesaler Data
                    </span>
                  </v-tooltip>
                </span>
              </v-card-title>
            <v-card-text>
              <Chart v-if="this.rx.series[0].data[0].y" :options="rx"/>
              <h5 class="text-center mb-2" v-if="this.rx.series[0].data[0].y == 0">
                Awaiting Data Feed from Member
              </h5>
            </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </article>
</template>

<script>
import { Chart } from 'highcharts-vue';
import dashboardService from '../services/dashboard.service';

export default {
  props: { ttmStart: String, ttmEnd: String },
  components: { Chart },
  data () {
    return {
      medsurg: {
        chart: {
          type: 'pie',
          height: 250 + 'vh',
          renderTo: 'container',
          backgroundColor: 'rgba(0,0,0,0)',
          animation: false,
          style: {
            fontFamily: 'Roboto'
          },
          credits: {
            enabled: false
          }
        },
        title: {
          text: undefined,
          align: 'center',
          verticalAlign: 'top',
          floating: true
        },
        credits: {
          enabled: false
        },
        tooltip: {
          formatter: function () {
            return '<span style="color: ' + this.point.color + '">' + this.point.name + ' \u25CF</span> ' + ' : ' + parseFloat(this.point.y).toFixed(1) + '%';
          }
        },
        series: [{
          innerSize: '50%',
          zMin: 0,
          name: '',
          data: [{ name: 'On Contract Spend', y: null, color: '#1E345D' }, { name: 'Off Contract Spend', y: null, color: '#eb8934' }],
          size: 200,
          showInLegend: false,
          dataLabels: {
            enabled: true,
            format: '{point.y:.1f}%',
            distance: -105,
            style: {
              fontSize: '20px',
              color: 'black'
            },
            filter: {
              property: 'percentage',
              operator: '>',
              value: 50
            }
          }
        }]
      },
      cumulative: {
        chart: {
          type: 'pie',
          height: 250 + 'vh',
          renderTo: 'container',
          backgroundColor: 'rgba(0,0,0,0)',
          animation: false,
          style: {
            fontFamily: 'Roboto'
          },
          credits: {
            enabled: false
          }
        },
        title: {
          text: undefined,
          align: 'center',
          verticalAlign: 'top',
          floating: true
        },
        credits: {
          enabled: false
        },
        tooltip: {
          formatter: function () {
            return '<span style="color: ' + this.point.color + '">' + this.point.name + ' \u25CF</span> ' + ' : ' + parseFloat(this.point.y).toFixed(1) + '%';
          }
        },
        series: [{
          innerSize: '50%',
          zMin: 0,
          name: '',
          data: [{ name: 'On Contract Spend', y: null, color: '#1E345D' }, { name: 'Off Contract Spend', y: null, color: '#eb8934' }],
          size: 200,
          showInLegend: false,
          dataLabels: {
            enabled: true,
            format: '{point.y:.1f}%',
            distance: -105,
            style: {
              fontSize: '20px',
              color: 'black'
            },
            filter: {
              property: 'percentage',
              operator: '>',
              value: 50
            }
          }
        }]
      },
      rx: {
        chart: {
          type: 'pie',
          height: 250 + 'vh',
          renderTo: 'container',
          backgroundColor: 'rgba(0,0,0,0)',
          animation: false,
          style: {
            fontFamily: 'Roboto'
          }
        },
        title: {
          text: undefined,
          align: 'center',
          verticalAlign: 'top',
          floating: true
        },
        credits: {
          enabled: false
        },
        tooltip: {
          formatter: function () {
            return '<span style="color: ' + this.point.color + '">' + this.point.name + ' \u25CF</span> ' + ' : ' + parseFloat(this.point.y).toFixed(1) + '%';
          }
        },
        series: [{
          innerSize: '50%',
          zMin: 0,
          name: '',
          data: [{ name: 'On Contract Spend', y: null, color: '#1E345D' }, { name: 'Off Contract Spend', y: null, color: '#eb8934' }],
          size: 200,
          showInLegend: false,
          dataLabels: {
            enabled: true,
            format: '{point.y:.1f} %',
            distance: -105,
            style: {
              fontSize: '20px',
              color: 'black'
            },
            filter: {
              property: 'percentage',
              operator: '>',
              value: 50
            }
          }
        }]
      },
      awardStatus: []
    };
  },
  async created () {
    this.contractPerformanceChartSetup();
  },
  methods: {
    contractPerformanceChartSetup () {
      dashboardService.getContractPerformance(this.$route.params.entityType, this.$route.params.entityId).then((res) => {
        const medsurg = (res.data.contractPerformance.find(item => item.contractPerformanceType === 'MedSurg Facility Invoice Feed'))
          ? res.data.contractPerformance.find(item => item.contractPerformanceType === 'MedSurg Facility Invoice Feed').contractPerformanceTotalSpend * 100
          : null;
        const total = (res.data.contractPerformance.find(item => item.contractPerformanceType === 'Total'))
          ? res.data.contractPerformance.find(item => item.contractPerformanceType === 'Total').contractPerformanceTotalSpend * 100
          : null;
        const rx = (res.data.contractPerformance.find(item => item.contractPerformanceType === 'Rx - Wholesaler Feed'))
          ? res.data.contractPerformance.find(item => item.contractPerformanceType === 'Rx - Wholesaler Feed').contractPerformanceTotalSpend * 100
          : null;

        this.medsurg.series[0].data[0].y = medsurg;
        this.medsurg.series[0].data[1].y = 100 - medsurg;
        this.cumulative.series[0].data[0].y = total;
        this.cumulative.series[0].data[1].y = 100 - total;
        this.rx.series[0].data[0].y = rx;
        this.rx.series[0].data[1].y = 100 - rx;
      });
      dashboardService.getAwardStatus(this.$route.params.entityType, this.$route.params.entityId).then((res) => {
        res.data.contractPerformanceAwardStatus.forEach((item) => {
          this.awardStatus.push(item);
        });
      });
    }
  }
};
</script>

<style scoped lang="scss">

.contract-performance-title {
  justify-content: center;
  text-align: center;
  word-break: normal !important;
}
.contract-performance-title-right {
  justify-content: center;
  text-align: center;
  word-break: normal !important;
  padding-right: 45px;
  padding-left: 45px;
}
#contract_performance {
  border-bottom-left-radius: 0%;
  border-bottom-right-radius: 0%;
}

#award-status-panels {
  margin-bottom: 12px;
  padding-left: 12px;
  padding-right: 12px;
}
::v-deep #award-status-table {
    thead {
      background-color: $blue;
      color: white;
      font-weight: 700;
      font-size: 11px;
      white-space: nowrap;
      border-left: 1px solid #dddddd;
      border-right: 1px solid #dddddd;
      border-bottom: 1px solid #dddddd;
      .off-contract{
        background-color: #eb8934 !important;
      }
    }
  td {
    padding-top: 10px;
    padding-bottom: 5px;
    border-bottom: 1px solid #dddddd;
    border-left: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
  }
}
</style>
