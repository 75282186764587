<template>
  <v-card>
    <v-card-title id="contact_table" ref="contact_table" class="justify-center">
      Executive Contacts
    </v-card-title>
    <v-data-table
      id="contact-table"
      @update:page="$vuetify.goTo($refs.contact_table)"
      dense
      :page.sync="page"
      :items-per-page="itemsPerPage"
      hide-default-footer
      @page-count="pageCount = $event"
      :headers="contactTableHeaders"
      :items="this.contactData"
    >
    <template v-slot:header.influenceType="{ header }">
      <v-tooltip top>
            <template v-slot:activator="{on, attrs}">
              <div v-bind="attrs" v-on="on">
                {{header.text}} <v-icon class="mb-2 jusify-center" small color="white"> mdi-information-outline</v-icon>
              </div>
            </template>
            <span>
              <u>Relationship Definitions:</u><br/>
              NEGATIVE: Does not support HealthTrust<br/>
              NONE: Has no relationship with any executive at Healthtrust<br/>
              NEUTRAL: Has a relationship with us but their view of HealthTrust is the same as that of any other vendor<br/>
              STRONG/POSITIVE: Views HealthTrust as a good organization that is contributing to their business success
            </span>
          </v-tooltip>
    </template>
    <template v-slot:item.influenceType="{ item }" class="text-center">
      <div v-if="item.influenceType != null && item.influenceType !== ''">
        <v-icon :color="getIconColor(item.influenceType)">{{ getInfluenceIcon(item.influenceType) }}</v-icon> <br/>
        <span>{{ item.influenceType }}</span>
      </div>
      <span v-else>Relationship Status Not Available</span>
    </template>
    <template
        v-slot:no-data
      >
      Awaiting data from Account Management Team
      </template>
  </v-data-table>
    <div v-if="pageCount > 1" class="pagination-div">
      <v-pagination
        v-model="page"
        :length="pageCount"
        circle
        color="#1e345d"
        prev-icon="mdi-menu-left"
        next-icon="mdi-menu-right"
      ></v-pagination>
    </div>
  </v-card>
</template>

<script>
export default {
  props: { contactData: Array },
  data () {
    return {
      contactTableHeaders: [
        { text: 'Executive Team', align: 'start', sortable: false, value: 'role' },
        { text: 'Name', value: 'name', sortable: false },
        { text: 'Relationship Status', value: 'influenceType', sortable: false, align: 'center' },
        { text: 'Comments', value: 'comment', sortable: false }
      ],
      page: 1,
      itemsPerPage: 12,
      pageCount: 0
    };
  },
  methods: {
    getInfluenceIcon (influenceType) {
      return (influenceType === 'Strong/Positive') ? 'mdi-thumb-up-outline'
        : (influenceType === 'Neutral') ? 'mdi-minus-thick'
          : (influenceType === 'Negative') ? 'mdi-thumb-down-outline'
            : (influenceType === 'None') ? 'mdi-circle-outline'
              : '';
    },
    getIconColor (influenceType) {
      return (influenceType === 'Strong/Positive') ? 'green'
        : (influenceType === 'Neutral') ? 'blue accent-1'
          : (influenceType === 'Negative') ? 'red'
            : 'grey';
    }
  }
};
</script>
