import Vue from 'vue';

const clickInside = (element, { target }) => (element === target || element.contains(target));
const runExpression = (event, binding, vnode) => {
  vnode.context[binding.expression](event);
  binding.value();
};

Vue.directive('click-outside', {
  bind (element, binding, vnode) {
    element.clickOutsideEvent = event => (!clickInside && runExpression(event, binding, vnode));
    document.body.addEventListener('click', element.clickOutsideEvent);
  },
  unbind (element) {
    document.body.removeEventListener('click', element.clickOutsideEvent);
  }
});
