<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="900" persistent>
      <template v-slot:activator="{ on, attrs}">
        <div v-bind="attrs" v-on="on" class="d-flex text-no-wrap">
          <v-icon :color="iconColor" class="mr-2"> {{ icon }} </v-icon>
        </div>
      </template>

      <v-card class="px-3 py-5 black--text">
        <v-card-title class="pa-5">
          <h4>ALERT</h4>
        </v-card-title>
        <v-card-text>
          <v-alert
            close-text="Close Alert"
            dark
            type="error"
          >
            You have unsaved changes on the Member Settings page
          </v-alert>
          <h6>Save or cancel changes to continue</h6>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="secondary"
            class="modal-button mx-3"
            @click="
            dialog = false;"
          >
            CLOSE
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'FeedbackModal',
  props:
  {
    iconColor: String,
    name: String,
    companyId: Number,
    gpoid: String,
    memberRetainedIds: Array,
    retainedIds: Array,
    icon: String
  },
  data () {
    return {
      dialog: false
    };
  },
  methods: {
    saveChanges () {
      this.$emit('save-changes');
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep #retained-table{
  .v-data-table-header {
    th {
      background-color: $blue;
      color: white !important;
      font-weight: 700;
      font-size: 15px;
      white-space: nowrap;
      padding: 15px;
      border-left: 1px solid #dddddd !important;
      border-right: 1px solid #dddddd !important;
    }
  }
  td {
    padding-top: 5px;
    padding-bottom: 5px;
    border-left: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
  }
  .v-data-table__mobile-table-row {
    border-top: 5px solid #dddddd;
  }
}
.add-row-btn{
  background-color: $blue !important;
  color: white !important;
}
textarea {
  width: 100%;
  resize: none !important;
}

#feedback-input {
  min-height: 250px;
  border: 1px solid #bcbcbc;
  border-radius: 5px;
}

#feedback-input:focus {
  outline: none !important;
  border-color: #0074b8;
}

button:disabled,
button:disabled:hover {
  background-color: #58595b;
  border: 0px;
}

::v-deep .v-btn--is-elevated {
  box-shadow: none;
}

::v-deep .v-btn.secondary {
  color: #0074b8 !important;
  border: 1px solid #0000001f !important;
}

.modal-button {
  padding: 8px 16px !important;
  width: 110px !important;
  height: 40px !important;
  border-radius: 8px;
}
</style>
