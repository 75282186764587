import { messageOptions } from './messagesCollection';
import { getMessage } from './messagesUtils';

const state = {
  showMessage: false,
  appMessage: '',
  timeout: -1,
  color: '#0174B8',
  textColor: '',
  customClass: ''
};

const getters = {
  getShowMessage (state) {
    return state.showMessage;
  },
  getAppMessage (state) {
    return state.appMessage;
  },
  getTimeout (state) {
    return state.timeout;
  },
  getColor (state) {
    return state.color;
  },
  getTextColor (state) {
    return state.textColor;
  },
  getCustomClass (state) {
    return state.customClass;
  }
};

const mutations = {
  setShowMessage (state, payload) {
    state.showMessage = payload;
  },
  setAppMessage (state, payload) {
    state.appMessage = payload;
  },
  setTimeout (state, payload) {
    state.timeout = payload;
  },
  setColor (state, payload) {
    state.color = payload;
  },
  setTextColor (state, payload) {
    state.textColor = payload;
  },
  setCustomClass (state, payload) {
    state.customClass = payload;
  }
};

const actions = {

  setMessage ({ commit, dispatch }, { message, timeout = -1, color = '#0174B8', textColor, showMessage, customClass }) {
    dispatch('messages/clearMessage', null, { root: true });
    commit('setTimeout', timeout);
    commit('setAppMessage', message);
    commit('setColor', color);
    commit('setTextColor', textColor);
    if (customClass?.length) {
      commit('setCustomClass', customClass);
    }
    commit('setShowMessage', showMessage);
  },
  clearMessage ({ commit }) {
    commit('setTimeout', -1);
    commit('setAppMessage', '');
    commit('setColor', '#0174B8');
    commit('setTextColor', '');
    commit('setCustomClass', '');
    commit('setShowMessage', false);
  },
  displayError ({ dispatch }, options) {
    const { error } = messageOptions;
    const message = getMessage(options);
    dispatch('setMessage', { ...error, message });
  },
  displaySuccess ({ dispatch }, options) {
    const { success } = messageOptions;
    const message = getMessage(options);
    dispatch('setMessage', { ...success, message });
  },
  displayDefault ({ dispatch }, options) {
    const { default: defaultOptions } = messageOptions;
    const message = getMessage(options);
    dispatch('setMessage', { ...defaultOptions, message, ...options });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
