<template>
  <div>
    <RetainedModal v-if="adminPageChanges === 0"
      v-on="$listeners"
      :name="name"
      :company-id="companyId"
      :gpoid="gpoid"
      :member-retained-ids="memberRetainedIds"
      :retained-ids="retainedIds"
      :icon="icon"
    />
    <UnsavedChangesModal v-else
      v-on="$listeners"
      :icon="icon"
    />
  </div>
</template>

<script>
import RetainedModal from './modals/RetainedGpoidModal.vue';
import UnsavedChangesModal from './modals/UnsavedChangesModal.vue';
import { mapActions } from 'vuex';

export default {
  props:
  {
    iconColor: String,
    name: String,
    companyId: Number,
    gpoid: String,
    memberRetainedIds: Array,
    retainedIds: Array,
    icon: String,
    adminPageChanges: Number
  },
  name: 'UserFeedback',
  components: {
    RetainedModal,
    UnsavedChangesModal
  },
  methods: {
    dataRefresh () {
      this.$emit('getCompanyInfo');
    },
    ...mapActions({
      postFeedback: 'dashboardItems/postFeedback'
    }),
    feedbackSubmitted (feedback) {
      this.postFeedback(feedback);
      this.$refs.submit.open();
    }
  }
};
</script>

<style>
</style>
