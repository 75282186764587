<template>
  <div>
    <v-card class="footprint-card">
      <v-card-title class="justify-center" ref="footprint_header">
        HealthTrust Footprint
      </v-card-title>
      <v-data-table id="footprint-table"
        @update:page="$vuetify.goTo($refs.footprint_header)"
        :page.sync="footprintPage"
        :items-per-page="itemsPerPage"
        @page-count="footprintPageCount = $event"
        dense
        hide-default-footer
        :headers="footprintHeaders"
        :items="footprintItems"
        :loading="footprintLoading"
        loading-text="Loading items...">
        <template v-slot:header.solutionStatus="{ header }">
          <v-tooltip top>
            <template v-slot:activator="{on, attrs}">
              <div v-bind="attrs" v-on="on">
                {{header.text}} <v-icon class="mb-2 jusify-center" small color="white"> mdi-information-outline</v-icon>
              </div>
            </template>
            <span>
              Legend: <br/>
              Green = Current customer of this service <br/>
              Yellow = Active discussions underway <br/>
              Red = Declined <br/>
              Grey = Not yet presented
            </span>
          </v-tooltip>
        </template>
        <template v-slot:progress>
        <v-progress-linear
          indeterminate
          color='#f38a00'
        ></v-progress-linear>
        </template>
        <template v-slot:item.solutionStatus="{ item }">
          <v-icon :color="getColor(item.solutionStatus)">{{ getIcon(item.solutionStatus) }}</v-icon>
        </template>
        <template
          v-slot:no-data
        >
        Awaiting data from Account Management Team
        </template>
      </v-data-table>
      <div v-if="footprintPageCount > 1" class="pagination-div">
        <v-pagination
          v-model="footprintPage"
          :length="footprintPageCount"
          circle
          color="#1e345d"
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
        >
        </v-pagination>
      </div>
    </v-card>
    <v-card class="services-card">
      <v-card-title class="justify-center" ref="services_resources_header">
        Services & Resources
      </v-card-title>
      <v-data-table id="services-resources-table"
        @update:page="$vuetify.goTo($refs.services_resources_header)"
        :page.sync="servicesResourcesPage"
        :items-per-page="itemsPerPage"
        @page-count="servicesResourcesPageCount = $event"
        dense
        hide-default-footer
        :headers="servicesResourcesHeaders"
        :items="servicesResourcesItems"
        :loading="servicesResourcesLoading"
        loading-text="Loading items...">
        <template v-slot:header.solutionStatus="{ header }">
          <v-tooltip top>
            <template v-slot:activator="{on, attrs}">
              <div v-bind="attrs" v-on="on">
                {{header.text}} <v-icon class="mb-2 jusify-center" small color="white"> mdi-information-outline</v-icon>
              </div>
            </template>
            <span>
              <u>Legend:</u> <br/>
              Green = Current customer of this service <br/>
              Yellow = Active discussions underway <br/>
              Red = Declined <br/>
              Grey = Not yet presented
            </span>
          </v-tooltip>
        </template>
        <template v-slot:progress>
        <v-progress-linear
          indeterminate
          color='#f38a00'
        ></v-progress-linear>
        </template>
        <template v-slot:item.solutionStatus="{ item }">
          <v-icon :color="getColor(item.solutionStatus)">{{ getIcon(item.solutionStatus) }}</v-icon>
        </template>
        <template
          v-slot:no-data
        >
        Awaiting data from Account Management Team
        </template>
      </v-data-table>
      <div v-if="servicesResourcesPageCount > 1" class="pagination-div">
        <v-pagination
          v-model="servicesResourcesPage"
          :length="servicesResourcesPageCount"
          circle
          color="#1e345d"
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
        ></v-pagination>
        </div>
    </v-card>
  </div>
</template>

<script>
import dashboardService from '../services/dashboard.service';

export default {
  data () {
    return {
      footprintHeaders: [
        { text: 'Offering', value: 'offering', width: 150, sortable: true },
        { text: 'Solution', value: 'solutionName', width: 150, sortable: true },
        { text: 'Status', value: 'solutionStatus', width: 100, align: 'center', sortable: true },
        { text: 'Comments/Next Steps', value: 'comments', sortable: false },
        { text: 'Healthtrust Resource(s)', value: 'healthTrustResource', sortable: false },
        { text: 'Member Owner(s)', value: 'owners', sortable: false }
      ],
      footprintItems: [],
      footprintLoading: true,
      footprintPage: 1,
      footprintPageCount: 0,

      servicesResourcesHeaders: [
        { text: 'Offering', value: 'offering', width: 150, sortable: true },
        { text: 'Solution', value: 'solutionName', width: 150, sortable: true },
        { text: 'Status', value: 'solutionStatus', width: 100, align: 'center', sortable: true },
        { text: 'Funding Source', value: 'fundingSource', sortable: false },
        { text: 'Comments/Next Steps', value: 'comments', sortable: false },
        { text: 'Healthtrust Resource(s)', value: 'healthTrustResource', sortable: false },
        { text: 'Member Owner(s)', value: 'owners', sortable: false }
      ],
      servicesResourcesItems: [],
      servicesResourcesLoading: true,
      servicesResourcesPage: 1,
      servicesResourcesPageCount: 0,

      itemsPerPage: 10
    };
  },
  mounted () {
    this.getFootprintItems();
  },
  methods: {
    getFootprintItems () {
      this.isLoading = true;
      dashboardService.getFootprint(this.$route.params.entityType, this.$route.params.entityId, 1).then((res) => {
        res.data.footprint.forEach((item) => {
          this.footprintItems.push(item);
        });
        this.footprintLoading = false;
      });
      dashboardService.getFootprint(this.$route.params.entityType, this.$route.params.entityId, 2).then((res) => {
        res.data.footprint.forEach((item) => {
          this.servicesResourcesItems.push(item);
        });
        this.servicesResourcesLoading = false;
      });
    },
    getColor (footprintStatus) {
      return (footprintStatus === 'Current customer of this service') ? 'green'
        : (footprintStatus === 'Active discussions underway') ? 'orange'
          : (footprintStatus === 'Declined') ? 'red'
            : 'grey lighten-1';
    },
    getIcon (footprintStatus) {
      return (footprintStatus === 'Current customer of this service') ? 'mdi-check-bold'
        : (footprintStatus === 'Active discussions underway') ? 'mdi-alert'
          : (footprintStatus === 'Declined') ? 'mdi-close-thick'
            : 'mdi-circle';
    }
  }

};
</script>

<style scoped lang="scss">
::v-deep .footprint-card {
  border-radius: 0%;
}
::v-deep .services-card {
  border-top-left-radius: 0%;
  border-top-right-radius: 0%;
}
::v-deep #footprint-table {
  .v-data-table-header {
    th {
      background-color: $blue;
      color: white !important;
      font-weight: 700;
      font-size: 15px;
      white-space: nowrap;
      padding: 15px;
      border-left: 1px solid #dddddd;
      border-right: 1px solid #dddddd;
    }
  }
  td {
    padding-top: 10px;
    padding-bottom: 5px;
    border-left: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
  }
  .v-data-table__mobile-table-row {
    border-top: 2px solid #dddddd;
    td {
      border-bottom: 0px;
    }
  }
  .v-data-table-header-mobile__wrapper {
    .v-input__slot {
      color: white !important;
    }
    .v-label {
      color: white !important;
    }
  }
}
::v-deep #services-resources-table {
  .v-data-table-header {
    th {
      background-color: $blue;
      color: white;
      font-weight: 700;
      font-size: 15px;
      white-space: nowrap;
      padding: 15px;
      border-left: 1px solid #dddddd;
      border-right: 1px solid #dddddd;
    }
  }
  td {
    padding-top: 10px;
    padding-bottom: 5px;
    border-left: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
  }
  .v-data-table__mobile-table-row {
    border-top: 2px solid #dddddd;
    td {
      border-bottom: 0px;
    }
  }
  .v-data-table-header-mobile__wrapper {
    .v-input__slot {
      color: white !important;
    }
    .v-label {
      color: white !important;
    }
  }
}
::v-deep .v-data-table-header__icon {
  color: white !important;
}
.pagination-div {
  padding-top: 5px;
  border-left: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 10px;
}
</style>
