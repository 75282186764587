export default {
  analytics: 'BI - Standard User-Rx',
  catalog: 'Catscan - Catalog User Med/Surg',
  contractRestrictions: 'Catscan - Contract Restrictions',
  distributorMarkup: 'Catscan - Distributor Markup Administrator',
  mexp: 'HealthTrust Portals - Member Portal User',
  ssr: 'SSR - Financial Reports Viewer',
  ed: 'ExecDash',
  Admin: 'ExecDash - Admin'
};
