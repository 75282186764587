import apiBase from './api-base';

const resource = '/Admin';
export default {
  getEDWLastUpdated () {
    return apiBase.get(`${resource}/edw-last-updated`);
  },
  getSalesforceLastUpdated () {
    return apiBase.get(`${resource}/salesforce-last-updated`);
  },
  getBaseLastUpdated () {
    return apiBase.get(`${resource}/base-last-updated`);
  },
  updateEDWStageTables () {
    return apiBase.get(`${resource}/update-edw`);
  },
  getRevenueLastUpdated () {
    return apiBase.get(`${resource}/revenue-kpi-last-updated`);
  },
  getAllCompanies () {
    return apiBase.get(`${resource}/get-all-companies`);
  },
  importRevenue (formData) {
    return apiBase.post(`${resource}/import-revenue`, formData);
  },
  updateIsVisible (formData) {
    const customConfig = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    };
    return apiBase.post(`${resource}/update-isvisible`, formData, customConfig);
  },
  updateRetainedGPOID (formData) {
    const customConfig = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    };
    return apiBase.post(`${resource}/update-retained-gpoid`, formData, customConfig);
  },
  getAllVisible () {
    return apiBase.get(`${resource}/get-all-visible`);
  }
};
