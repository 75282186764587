<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="900" persistent>
      <template v-slot:activator="{ on, attrs}">
        <div v-bind="attrs" v-on="on" class="d-flex text-no-wrap">
          <v-icon :color="iconColor" class="mr-2"> {{ icon }} </v-icon>
        </div>
      </template>
      <v-card class="px-3 py-5 black--text">
        <v-card-title class="pa-5">
          <h4>{{ name }} | Company ID: {{ companyId }} | GPOID: {{ gpoid }}</h4>
        </v-card-title>
        <v-card-text>
        </v-card-text>
        <v-card-text>
          <v-container>
            <span>
            </span>
            <v-row>
              <v-col cols="8" offset="2">
                <v-form @submit.prevent ref="addForm" v-model="addRowIsValid">
                  <v-text-field
                  label="Add Retained GPOID"
                  v-model="idInput"
                  counter
                  maxlength="7"
                  :rules="[rules.gpoidFormat, rules.counter, rules.unique, rules.uniqueGlobal]"
                  >
                    <template v-slot:append-outer>
                      <v-tooltip right>
                        <template v-slot:activator="{on, attrs}">
                          <v-btn
                            @click="addId(idInput)"
                            :disabled="!addRowIsValid"
                            icon
                            v-bind="attrs"
                            v-on="on">
                            <v-icon large>mdi-plus</v-icon>
                          </v-btn>
                        </template>
                        Save Retained GPOID
                      </v-tooltip>
                    </template>
                  </v-text-field>
                </v-form>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-data-table
                id="retained-table"
                height="40vh"
                :items="this.memberGpoids"
                :headers="this.retainedHeaders"
                :items-per-page="-1"
                hide-default-footer>
                  <template v-slot:item.gpoid="{ item }">
                    <v-container v-if="item.editing === false">
                      <v-row>
                        <v-col>
                          {{ item.gpoid }}
                        </v-col>
                        <v-spacer/>
                        <v-col v-if="item.deleting === false">
                          <v-btn
                            small
                            color="primary"
                            @click="
                              item.editing = !item.editing
                              memberGpoids.forEach((item) => item.disabled = true)
                              editInput = item.gpoid"
                            :disabled="item.disabled"
                          >
                            EDIT
                          </v-btn>
                          &nbsp;
                          <v-btn
                            small
                            color="error"
                            @click="item.deleting = !item.deleting"
                            :disabled="item.disabled"
                          >
                            DELETE
                          </v-btn>
                        </v-col>
                        <v-col v-else>
                          <v-btn
                            small
                            color="error"
                            @click="removeId(item)"
                          >
                            CONFIRM
                          </v-btn>
                          &nbsp;
                          <v-btn
                            small
                            color="grey lighten-1"
                            @click="item.deleting = !item.deleting"
                          >
                            CANCEL
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-container>
                    <v-container v-else>
                      <v-row>
                        <v-col>
                          <v-form @submit.prevent ref="editForm" v-model="editRowIsValid">
                            <v-text-field
                            dense
                            v-model="editInput"
                            :value="item.gpoid"
                            counter
                            maxlength="7"
                            :rules="[rules.gpoidFormat, rules.counter, rules.unique, rules.uniqueGlobal]"
                            >
                            </v-text-field>
                          </v-form>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col>
                          <v-btn
                            small
                            color="grey lighten-1"
                            @click="item.editing = !item.editing
                              memberGpoids.forEach((item) => item.disabled = false)"
                          >
                            CANCEL
                          </v-btn>
                          &nbsp;
                          <v-btn
                            small
                            color="primary"
                            :disabled="!editRowIsValid"
                            @click="editId(item, editInput)"
                          >
                            SUBMIT
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-container>
                  </template>
              </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="secondary"
            class="modal-button mx-3"
            @click="
            modalDataRefresh()
            clearInput()
            dialog = false;"
          >
            CLOSE
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import adminService from '../../services/admin.service';
export default {
  name: 'FeedbackModal',
  props:
  {
    iconColor: String,
    name: String,
    companyId: Number,
    gpoid: String,
    memberRetainedIds: Array,
    retainedIds: Array,
    icon: String
  },
  data () {
    return {
      memberGpoids: [],
      globalGpoids: [],
      dialog: false,
      idInput: '',
      editInput: '',
      addIds: [],
      addRowIsValid: false,
      editRowIsValid: false,
      edits: 0,
      retainedHeaders:
      [
        { text: 'Retained GPOID', value: 'gpoid' }
      ],
      rules: {
        counter: value => value.length <= 7 || 'Max 7 characters',
        gpoidFormat: value => {
          const pattern = /^[A-Za-z]\d{6}$/;
          return pattern.test(value) || 'Invalid Format';
        },
        unique: value => !this.memberGpoids.find(item => item.gpoid === value.toUpperCase()) || 'This Retained GPOID is already in use',
        uniqueGlobal: value => !this.globalGpoids.find(item => item === value.toUpperCase()) || 'This Retained GPOID is already in use by another member'
      }
    };
  },
  async created () {
    this.gpoidSetup();
  },
  methods: {
    gpoidSetup () {
      if (this.memberRetainedIds.length > 0) {
        this.memberRetainedIds.forEach((item) => {
          this.memberGpoids.push(
            {
              gpoid: item,
              editing: false,
              deleting: false,
              disabled: false
            }
          );
        });
        this.retainedIds.forEach((item) => {
          this.globalGpoids.push(item.gpoid);
        });
      }
    },
    clearInput () {
      this.idInput = '';
      this.memberGpoids.forEach((item) => {
        item.editing = false;
        item.deleting = false;
      });
    },
    removeId (item) {
      const bodyFormData = new FormData();
      bodyFormData.append('gpoidToUpdate', item.gpoid);
      bodyFormData.append('companyID', this.companyId);
      bodyFormData.append('operation', 'd');

      adminService.updateRetainedGPOID(bodyFormData);

      const index = this.memberGpoids.indexOf(item);
      const globalIndex = this.globalGpoids.indexOf(item.gpoid);
      if (index > -1 && globalIndex > -1) {
        this.memberGpoids.splice(index, 1);
        this.globalGpoids.splice(globalIndex, 1);
      }
      this.edits = this.edits + 1;
    },
    addId (gpoid) {
      if (gpoid !== null) {
        gpoid = gpoid.toUpperCase();
        const bodyFormData = new FormData();
        bodyFormData.append('gpoidToUpdate', gpoid);
        bodyFormData.append('companyID', this.companyId);
        bodyFormData.append('operation', 'a');
        this.idInput = '';
        this.addRowIsValid = false;
        adminService.updateRetainedGPOID(bodyFormData);
        this.memberGpoids.unshift(
          {
            companyId: this.companyId,
            gpoid: gpoid,
            editing: false,
            deleting: false
          });
        this.globalGpoids.push(gpoid);
        this.edits = this.edits + 1;
        this.$refs.addForm.resetValidation();
      }
    },
    editId (item, newGpoid) {
      if (item !== null && newGpoid !== null) {
        newGpoid = newGpoid.toUpperCase();
        const bodyFormData = new FormData();
        bodyFormData.append('gpoidToUpdate', item.gpoid);
        bodyFormData.append('companyID', this.companyId);
        bodyFormData.append('operation', 'u');
        bodyFormData.append('newGpoid', newGpoid);

        adminService.updateRetainedGPOID(bodyFormData).then((res) => {
          if (res.status === 200) {
            item.editing = false;
            this.memberGpoids.forEach((item) => {
              item.disabled = false;
            });
          }
        });
        const index = this.memberGpoids.indexOf(item);
        const globalIndex = this.globalGpoids.indexOf(item.gpoid);
        if (index > -1 && globalIndex > -1) {
          this.memberGpoids[index].gpoid = newGpoid;
          this.globalGpoids[globalIndex] = newGpoid;
        }
        this.$refs.editForm.resetValidation();
      }
      this.edits = this.edits + 1;
    },
    modalDataRefresh () {
      if (this.edits > 0) {
        this.$emit('modal-refresh');
      }
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep #retained-table{
  .v-data-table-header {
    th {
      background-color: $blue;
      color: white !important;
      font-weight: 700;
      font-size: 15px;
      white-space: nowrap;
      padding: 15px;
      border-left: 1px solid #dddddd !important;
      border-right: 1px solid #dddddd !important;
    }
  }
  td {
    padding-top: 5px;
    padding-bottom: 5px;
    border-left: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
  }
  .v-data-table__mobile-table-row {
    border-top: 5px solid #dddddd;
  }
}
.add-row-btn{
  background-color: $blue !important;
  color: white !important;
}
textarea {
  width: 100%;
  resize: none !important;
}

#feedback-input {
  min-height: 250px;
  border: 1px solid #bcbcbc;
  border-radius: 5px;
}

#feedback-input:focus {
  outline: none !important;
  border-color: #0074b8;
}

button:disabled,
button:disabled:hover {
  background-color: #58595b;
  border: 0px;
}

::v-deep .v-btn--is-elevated {
  box-shadow: none;
}

::v-deep .v-btn.secondary {
  color: #0074b8 !important;
  border: 1px solid #0000001f !important;
}

.modal-button {
  padding: 8px 16px !important;
  width: 110px !important;
  height: 40px !important;
  border-radius: 8px;
}
</style>
