import axios from 'axios';
import router from '@/router';
import Vue from 'vue';

const baseURL = '/ma/executive-dashboard-internal/api';

const instance = axios.create({
  baseURL
});

instance.interceptors.request.use(async (request) => {
  const um = Vue.prototype.$userManager;
  const user = await um.getUser();
  const token = user.access_token;

  if (token) {
    request.headers.common.Authorization = `Bearer ${token}`;
  }
  return request;
});

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401 || error.response.status === 403) {
      sessionStorage.removeItem('token');
      if (router.currentRoute.path) {
        if (router.currentRoute.name === 'Landing') {
          // do nothing
        } else {
          router.push(`/?redirectAfterLogin=${router.currentRoute.path}`);
        }
      } else {
        router.push({ name: 'Landing' });
      }
    }
    return Promise.reject(error);
  }
);

export default instance;
