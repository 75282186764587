<template>
  <v-card>
    <div v-for="(item, i) in displayValues" :key="i">
      <v-tooltip v-if="item.tooltip" top max-width="400">
        <template v-slot:activator="{on, attrs}">
          <v-card-text>
            <span
              class="label"
            >
              {{item.title}}
            </span>
            : {{item.value}}
            <v-icon class="ml-1" small color="#1E345D" v-on="on" v-bind="attrs">mdi-information-outline</v-icon>
          </v-card-text>
      </template>
      {{ item.tooltip }}
    </v-tooltip>
    <v-card-text v-else>
      <span
        class="label"
      >
        {{item.title}}
      </span>
      : {{item.value}}
    </v-card-text>
    <v-divider class="mx-3"></v-divider>
    </div>
  </v-card>
</template>

<script>
export default {
  props: { displayValues: Array }
};
</script>

<style>
.label{
  font-weight: bold;
}
</style>
