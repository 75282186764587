<template>
  <article>
    <v-card id="spend_growth">
      <v-card-title
        class="justify-center text-center"
        style="word-break: break-word"
      >
        Spend through GPO Trend &nbsp;
        <v-tooltip v-model="show" top max-width="400" open-on-click>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="#1E345D" @click="show = !show" v-bind="attrs" v-on="on"
              >mdi-information-outline</v-icon
            >
          </template>
          <span>
            Year over year quarterly member spend through GPO. Current year displays completed quarter to date member spend through GPO.
            Source: RAFT Allocated Fees & Retained Spend
          </span>
        </v-tooltip>
      </v-card-title>
      <v-card-subtitle class="justify-center text-center caption font-italic">
        <i>Values shown below are based on calendar year quarterly values</i>
      </v-card-subtitle>
      <Chart
        v-if="this.chartOptions.xAxis.categories.length > 0"
        :options="chartOptions"
      />
      <span class="text-center grey--text" v-if="this.chartOptions.xAxis.categories.length === 0">Awaiting Data Feed from Member</span>
    </v-card>
  </article>
</template>

<script>
import { Chart } from 'highcharts-vue';
import dashboardService from '../services/dashboard.service';

export default {
  components: { Chart },
  data () {
    return {
      show: false,
      chartOptions: {
        plotOptions: {
          column: {
            stacking: 'normal',
            dataLabels: {
              enabled: false
            }
          }
        },
        chart: {
          type: 'column',
          style: {
            fontFamily: 'Roboto'
          }
        },
        title: {
          text: ''
        },
        credits: {
          enabled: false
        },
        tooltip: {
          formatter: function () {
            const currencyFormatter = new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
              maximumFractionDigits: 0
            });
            return this.series.name + ': ' + currencyFormatter.format(this.y);
          }
        },
        xAxis: {
          categories: []
        },
        yAxis: {
          min: 0,
          title: {
            text: ''
          },
          stackLabels: {
            enabled: true,
            formatter: function () {
              const currencyFormatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                maximumFractionDigits: 0
              });
              return currencyFormatter.format(this.total);
            }
          },
          labels: {
            // eslint-disable-next-line no-template-curly-in-string
            format: '${text}'
          }
        },
        series: [
          {
            name: 'Q1',
            data: [],
            color: '#1E345D',
            legendIndex: 0,
            index: 3
          },
          {
            name: 'Q2',
            data: [],
            color: '#99caea',
            legendIndex: 1,
            index: 2
          },
          {
            name: 'Q3',
            data: [],
            color: '#f38a00',
            legendIndex: 2,
            index: 1
          },
          {
            name: 'Q4',
            data: [],
            color: '#ffd194',
            legendIndex: 3,
            index: 0
          }
        ]
      }
    };
  },
  async created () {
    this.spendGrowthChartSetup();
  },
  methods: {
    spendGrowthChartSetup () {
      dashboardService
        .getSpendGrowth(this.$route.params.entityType, this.$route.params.entityId, 5)
        .then((res) => {
          if (res.data.spendGrowth !== null) {
            const spendGrowth = res.data.spendGrowth;
            const currentYear = new Date().getFullYear();
            const years = [];
            const Q1 = [];
            const Q2 = [];
            const Q3 = [];
            const Q4 = [];
            var total = 0;

            if (spendGrowth && spendGrowth.length) {
              res.data.spendGrowth.forEach(year => {
                total += (year.q1 + year.q2 + year.q3 + year.q4);
              });
              if (total > 0) {
                while (spendGrowth.length < 5) {
                  if (spendGrowth[spendGrowth.length - 1].year !== currentYear) {
                    spendGrowth.push({
                      year: spendGrowth[spendGrowth.length - 1].year + 1,
                      q1: 0,
                      q2: 0,
                      q3: 0,
                      q4: 0
                    });
                  } else if (
                    spendGrowth[spendGrowth.length - 1].year === currentYear
                  ) {
                    for (let i = 0; i < 4; i++) {
                      if (spendGrowth[i].year !== currentYear - (4 - i)) {
                        spendGrowth.splice(i, 0, {
                          year: currentYear - (4 - i),
                          q1: 0,
                          q2: 0,
                          q3: 0,
                          q4: 0
                        });
                      }
                    }
                  }
                }
                spendGrowth.forEach((item) => {
                  years.push(item.year);
                  Q1.push(item.q1);
                  Q2.push(item.q2);
                  Q3.push(item.q3);
                  Q4.push(item.q4);
                });
                this.chartOptions.xAxis.categories = years;
                this.chartOptions.series[0].data = Q1;
                this.chartOptions.series[1].data = Q2;
                this.chartOptions.series[2].data = Q3;
                this.chartOptions.series[3].data = Q4;
              }
            }
          }
        });
    }
  }
};
</script>
