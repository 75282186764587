import { WebStorageStateStore } from 'oidc-client-ts';
import runtimeConfig from '../../runtimeConfig';

const pingDomain = runtimeConfig.AUTH_DOMAIN;
const urlBase = runtimeConfig.URL_BASE;
const clientId = runtimeConfig.CLIENT_ID;

const authSettings = {
  authority: pingDomain,
  client_id: clientId,
  filterProtocolClaims: true,
  loadUserInfo: true,
  post_logout_redirect_uri: `${urlBase}/auth`,
  redirect_uri: `${urlBase}/auth`,
  response_type: 'code',
  response_mode: 'query',
  scope: 'openid profile oidc_all',
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  metadata: {
    authorization_endpoint: `${pingDomain}/as/authorization.oauth2`,
    end_session_endpoint: `${pingDomain}/idp/startSLO.ping`,
    issuer: pingDomain,
    jwks_uri: `${pingDomain}/pf/JWKS`,
    token_endpoint: `${pingDomain}/as/token.oauth2`,
    userinfo_endpoint: `${pingDomain}/idp/userinfo.openid`
  }
};

export default authSettings;
