<template>
  <v-card>
    <v-card-title class="justify-center"> KPI Overview </v-card-title>
    <v-card-subtitle class="text-center caption font-italic">
      <i>
        Values shown below are based on the member's fiscal year ({{
          fiscalYearStart
            ? this.months[fiscalYearStart.getMonth()]
            : 'Date not currently available'
        }}
        -
        {{
          fiscalYearEnd
            ? this.months[fiscalYearEnd.getMonth()]
            : 'Date not currently available'
        }})
      </i>
    </v-card-subtitle>
    <v-data-table
      id="kh-table"
      dense
      :headers="khTableHeaders"
      :items="khTableItems"
      :loading="isLoading"
      hide-default-footer
      v-if="khTableItems.length > 0"
    >
      <template v-slot:progress>
        <v-progress-linear indeterminate color="#f38a00"></v-progress-linear>
      </template>
      <template v-slot:item.name="{ item }">
        <v-tooltip top max-width="400">
          <template v-slot:activator="{ on, attrs }">
            <v-layout text-left>
              {{ item.name }}
              <v-spacer />
              <v-icon small color="#1E345D" v-on="on" v-bind="attrs"
                >mdi-information-outline</v-icon
              >
            </v-layout>
          </template>
          {{ item.tooltip }}
        </v-tooltip>
      </template>
      <template v-slot:no-data> </template>
    </v-data-table>
    <v-data-table
      id="km-table"
      dense
      :headers="kmTableHeaders"
      :items="kmTableItems"
      :loading="isLoading"
      hide-default-footer
      v-if="kmTableItems.length > 0"
    >
      <template v-slot:progress>
        <v-progress-linear indeterminate color="#f38a00"></v-progress-linear>
      </template>
      <template v-slot:item.name="{ item }">
        <v-tooltip top max-width="400">
          <template v-slot:activator="{ on, attrs }">
            <v-layout text-left>
              {{ item.name }}
              <v-spacer />
              <v-icon small color="#1E345D" v-on="on" v-bind="attrs"
                >mdi-information-outline</v-icon
              >
            </v-layout>
          </template>
          {{ item.tooltip }}
        </v-tooltip>
      </template>
    </v-data-table>
    <v-data-table
      id="sm-table"
      dense
      :headers="smTableHeaders"
      :items="smTableItems"
      :loading="isLoading"
      hide-default-footer
      v-if="smTableItems.length > 0"
    >
      <template v-slot:progress>
        <v-progress-linear indeterminate color="#f38a00"></v-progress-linear>
      </template>
      <template v-slot:item.name="{ item }">
        <v-tooltip top max-width="400">
          <template v-slot:activator="{ on, attrs }">
            <v-layout text-left>
              {{ item.name }}
              <v-spacer />
              <v-icon small color="#1E345D" v-on="on" v-bind="attrs"
                >mdi-information-outline</v-icon
              >
            </v-layout>
          </template>
          {{ item.tooltip }}
        </v-tooltip>
      </template>
      <template v-slot:footer>
        <v-footer v-if="smTableItems.length > 0" class="caption font-italic"
          ><i>
            * Missing values in Member Provided Stats and Supply Metrics
            indicate awaiting data from member
          </i>
        </v-footer>
      </template>
    </v-data-table>
    <v-card v-if="khTableItems.length === 0">
      <v-card-title class="justify-center">
        Key HealthTrust Stats
      </v-card-title>
      <v-card-subtitle class="text-center">
        Awaiting Data Feed from Member and data from John Baker's Team
      </v-card-subtitle>
    </v-card>
    <v-card v-if="kmTableItems.length === 0">
      <v-card-title class="justify-center">
        Key Member Stats
      </v-card-title>
      <v-card-subtitle class="text-center">
        Awaiting Data Feed from Member
      </v-card-subtitle>
    </v-card>
    <v-card v-if="smTableItems.length === 0">
      <v-card-title class="justify-center">
        Supply Metrics
      </v-card-title>
      <v-card-subtitle class="text-center">
        Awaiting Data Feed from Member
      </v-card-subtitle>
    </v-card>
  </v-card>
</template>

<script>
import dashboardService from '../services/dashboard.service';

export default {
  props: { fiscalYearStart: Date, fiscalYearEnd: Date },
  data () {
    return {
      years: [],
      khTableHeaders: [
        {
          text: 'Key HealthTrust Stats',
          value: 'name',
          width: 300,
          sortable: false
        }
      ],
      kmTableHeaders: [
        {
          text: 'Member Provided Stats',
          value: 'name',
          width: 300,
          sortable: false
        }
      ],
      smTableHeaders: [
        {
          text: 'Supply Metrics',
          value: 'name',
          width: 300,
          sortable: false
        }
      ],
      khTableItems: [],
      kmTableItems: [],
      smTableItems: [],
      isLoading: true,
      months: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
      ]
    };
  },
  mounted () {
    this.getKPIValues();
  },
  methods: {
    checkForEmptyRows (tableItems) {
      var itemsArray = [];
      tableItems.forEach((item) => {
        var total = null;
        this.years.forEach((year) => {
          if (item[year] !== null && item[year] !== undefined) {
            total += item[year];
          }
        });
        if (total !== null) {
          itemsArray.push(item);
        }
      });
      return itemsArray;
    },
    getKPIValues () {
      dashboardService.getKPI(this.$route.params.entityType, this.$route.params.entityId, 5).then((res) => {
        this.isLoading = true;
        res.data.titles.forEach((item) => {
          this.years.push(item.title);
          this.khTableHeaders.push({
            text: 'FY ' + item.title,
            value: item.title,
            align: 'end',
            width: 140,
            sortable: false
          });
          this.kmTableHeaders.push({
            text: 'FY ' + item.title,
            value: item.title,
            align: 'end',
            width: 140,
            sortable: false
          });
          this.smTableHeaders.push({
            text: 'FY ' + item.title,
            value: item.title,
            align: 'end',
            width: 140,
            sortable: false
          });
        });
        this.khTableItems = this.checkForEmptyRows(res.data.khKPIs);
        this.kmTableItems = this.checkForEmptyRows(res.data.kmKPIs);
        this.smTableItems = this.checkForEmptyRows(res.data.smKPIs);
        this.isLoading = false;
      });
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep #kh-table {
  .v-data-table-header {
    th {
      background-color: $blue;
      color: white;
      font-weight: 700;
      font-size: 15px;
      white-space: nowrap;
      padding: 15px;
      border-left: 1px solid #dddddd;
      border-right: 1px solid #dddddd;
    }
  }
  td {
    padding-top: 10px;
    padding-bottom: 5px;
    border-left: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
  }
  .v-data-table__mobile-table-row {
    border-top: 5px solid #dddddd;
  }
}
::v-deep #km-table {
  .v-data-table-header {
    th {
      background-color: $blue;
      color: white;
      font-weight: 700;
      font-size: 15px;
      white-space: nowrap;
      padding: 15px;
      border-left: 1px solid #dddddd;
      border-right: 1px solid #dddddd;
      border-bottom: 1px solid #dddddd;
    }
  }
  td {
    padding-top: 10px;
    padding-bottom: 5px;
    border-left: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
  }
  .v-data-table__mobile-table-row {
    border-top: 5px solid #dddddd;
  }
}
::v-deep #sm-table {
  .v-data-table-header {
    th {
      background-color: $blue;
      color: white;
      font-weight: 700;
      font-size: 15px;
      white-space: nowrap;
      padding: 15px;
      border-left: 1px solid #dddddd;
      border-right: 1px solid #dddddd;
      border-bottom: 1px solid #dddddd;
    }
  }
  td {
    padding-top: 10px;
    padding-bottom: 5px;
    border-left: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
  }
  .v-data-table__mobile-table-row {
    border-top: 5px solid #dddddd;
  }
}
</style>
