<template>
  <article class="home-page">
    <header id="page-header" class="pa-15">
       <p id="homepage-title">Welcome to the Executive Dashboard &mdash; {{ user.firstName }}! </p>
      <div class="horizontal-divider"></div>
    </header>
    <section class="pa-15">
      <v-row class="card-row">
        <v-col>
          <CompanyList/>
        </v-col>
      </v-row>
    </section>
  </article>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import CompanyList from '../components/CompanyList.vue';

export default {
  components: {
    CompanyList
  },
  computed: {
    ...mapGetters({
      user: 'getUser'
    }),
    userRoles () {
      return this.user.roles.map(role => role.RoleName);
    }
  },
  methods: {
    ...mapActions({
      setMessage: 'messages/setMessage'
    }),
    handleLink (e) {
      if (e.external) {
        window.open(e.path, '_blank');
      } else {
        this.$router.push(e.path);
      }
    }
  }
};
</script>

<style lang="scss">
  .home-page {
    font-family: 'Roboto';

    .homepage-card {
      background: white;
      box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
      border-radius: 5px;
      padding: 30px;
      transition: 300ms;
      min-height: 100%;
    }

    .homepage-card:hover {
      cursor: pointer;
      box-shadow: 0px 12px 17px rgba(0, 0, 0, 0.14), 0px 5px 22px rgba(0, 0, 0, 0.12), 0px 7px 8px rgba(0, 0, 0, 0.2);
    }

    #resources-and-news-card:hover {
      background-color: #fcf4eb;
    }

    .heading-3 {
      font-family: Roboto;
      font-size: 21px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: -0.5px;
      color: #1E345D;
    }

    .body-1 {
      font-family: Roboto;
      font-size: 15px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: -0.1px;
      color: #58595B
    }

    #homepage-title {
      font-size: 52px;
      font-weight: 700;
      line-height: 52px;
      letter-spacing: -1.7px;
      text-align: center;
      color: #1E345D;
    }

    #page-header {
      background: white;
    }

    .horizontal-divider {
      width: 80px;
      height: 4px;
      margin: auto;
      /* Orange on Light */
      background: #DB7100;
    }

    .theme--light.v-divider {
        border-color: white !important;
        opacity: 0.5;
    }

    #no-bi-role-div {
      max-width: 50%;
      margin: auto
    }
  }
</style>
