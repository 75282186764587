import isPast from 'date-fns/isPast';
import isFuture from 'date-fns/isFuture';
import parseISO from 'date-fns/parseISO';
import { utcToZonedTime } from 'date-fns-tz';
import addDays from 'date-fns/addDays';
import addHours from 'date-fns/addHours';
import format from 'date-fns/format';

const dateIsPast = (date) => {
  const isoDate = parseISO(date);
  return isPast(isoDate);
};

const dateIsFuture = (date) => {
  const isoDate = parseISO(date);
  return isFuture(isoDate);
};

const getTomorrow = () => {
  return addDays(new Date(), 1);
};

const getLater = () => {
  return addHours(new Date(), 4);
};

const datePrettyPrint = (date, timezone = '') => {
  if (date != null) {
    if (timezone) {
      const timezoneDate = utcToZonedTime(date, 'UTC');
      return format(timezoneDate, 'MMM d yyy');
    }

    const isoDate = parseISO(date);
    return format(isoDate, 'MM/dd/yyyy');
  }
};

const fullDatePrint = (date) => {
  const isoDate = parseISO(date);
  return format(isoDate, 'MMMM dd, yyy');
};

export default {
  dateIsPast,
  dateIsFuture,
  getTomorrow,
  getLater,
  datePrettyPrint,
  fullDatePrint
};
